import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import { AutocompleteGetTagProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import { LooseObject } from "../types";

interface Props {
  options: Array<LooseObject>;
  loading: boolean;
  label: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  onSearch: (query: string) => void;
  onSelect: (selected: any) => void;
  onBlur?: () => void;
  value?: any;
  valueFrom?: string;
  textFrom?: string;
  shrink?: boolean;
  multiple?: boolean;
  inputValue?: string;
  defaultValue?: string[];
  renderTags?: (
    value: any[],
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode;
}

function Component({
  options,
  loading,
  label,
  name,
  placeholder,
  required,
  onSearch,
  onSelect,
  onBlur,
  value,
  valueFrom,
  textFrom,
  shrink,
  multiple,
  inputValue,
  defaultValue,
  renderTags,
}: Props) {
  return (
    <Autocomplete
      size="small"
      options={options}
      loading={loading || false}
      value={value}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === "object") {
          return option[valueFrom || "value"] === value[valueFrom || "value"];
        }

        return option === value;
      }}
      getOptionLabel={(option) => {
        if (typeof option === "object") {
          return option[textFrom || "text"];
        }

        return option;
      }}
      onChange={(event, value) => onSelect(value)}
      autoComplete
      filterOptions={(options, state) => options}
      onInputChange={(event, value, reason) => {
        if (
          typeof onSearch === "function" &&
          (reason === "input" || reason === "clear")
        ) {
          onSearch(value);
        }
      }}
      fullWidth
      onFocus={(event) => {
        if (!options.length) {
          onSearch("");
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          name={name}
          placeholder={placeholder}
          required={required || false}
          variant="outlined"
          InputLabelProps={{
            shrink: shrink,
          }}
          InputProps={{
            ...params.InputProps,
            required: required,
            endAdornment: (
              <>
                {loading || false ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      multiple={multiple}
      inputValue={inputValue}
      defaultValue={defaultValue}
      renderTags={renderTags}
      onBlur={onBlur}
    />
  );
}

export default Component;
