export const CREATE = "support.tickets.CREATE";
export const CREATE_FAILED = "support.tickets.CREATE_FAILED";
export const CREATE_OK = "support.tickets.CREATE_OK";

export const PAGINATE = "support.tickets.PAGINATE";
export const PAGINATE_FAILED = "support.tickets.PAGINATE_FAILED";
export const PAGINATE_OK = "support.tickets.PAGINATE_OK";

export const SET_FIELDS = "support.tickets.SET_FIELDS";
export const CLEAR_FIELDS = "support.tickets.CLEAR_FIELDS";
export const RESET_ERROR = "support.tickets.RESET_ERROR";

export const SHOW = "support.tickets.SHOW";
export const SHOW_FAILED = "support.tickets.SHOW_FAILED";
export const SHOW_OK = "support.tickets.SHOW_OK";
