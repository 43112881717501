import React from "react";
import { useAuth } from "@akj-dev/affinity-platform";
import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";
import { Ticket } from "../../../../types";
import { humanReadableSlaDuration } from "../../../../helpers/functions";
import Attachments from "./Attachments";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

interface Props {
  ticket: Ticket;
}

export default function Component({ ticket }: Props) {
  const { accountSettings } = useAuth();
  const classes = useStyles();

  const SLAIcon = () => {
    if (ticket.sla?.time_remaining > 0) {
      return <CheckIcon className={classes.icon} />;
    }

    return <CloseIcon className={classes.icon} />;
  };

  const SLAStatus = () => {
    if (ticket.date_resolved) {
      return (
        <>
          resolved in{" "}
          {humanReadableSlaDuration(ticket.sla?.time_remaining_human_readable)}
        </>
      );
    }

    if (ticket.sla?.time_remaining > 0) {
      return (
        <>
          {humanReadableSlaDuration(ticket.sla?.time_remaining_human_readable)}{" "}
          remaining
        </>
      );
    }

    return (
      <>
        {humanReadableSlaDuration(ticket.sla?.time_remaining_human_readable)}{" "}
        exceeded
      </>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Typography paragraph={true} variant="h4">
          {ticket.name}
        </Typography>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: ticket.description_as_html }}
        />
        <Attachments
          attachments={ticket?.notes[0]?.attachments}
          ticketId={ticket.id}
        />
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="caption">Company Name</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4">{ticket.account_name}</Typography>
              </Grid>
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="caption">Account Number</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4">
                  {ticket.account?.billing_account_number}
                </Typography>
              </Grid>
              <Divider />
            </Grid>
          </Grid>

          {accountSettings.affinity_support_permission_view_sla === "1" && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="caption">
                    <SLAIcon /> SLA
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography paragraph variant="h4">
                    <SLAStatus />
                  </Typography>
                </Grid>
                <Divider />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
