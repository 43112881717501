import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StringParam, withDefault, useQueryParams } from "use-query-params";
import { useAuth } from "@akj-dev/affinity-platform";
import { Paper, Tab, Tabs, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Comments from "../../Comments";
import Details from "./components/Details";
import Info from "./components/Info";
import SLA from "./components/SLA";
import Main from "../../../layout/Main";
import { LooseObject } from "../../../types";
import { clearFields, show } from "../../../store/tickets/actions";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(0, 1.5, 1.5),
  },
  divider: {
    marginBottom: theme.spacing(1.5),
  },
  divider2: {
    marginTop: theme.spacing(1.5),
  },
}));

export default function Ticket() {
  const { accountSettings } = useAuth();

  const { error, loading, ticket } = useSelector((state: any) => state.tickets);
  const { id } = useParams<LooseObject>();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [query, setQuery] = useQueryParams({
    section: withDefault(StringParam, "info"),
  });

  useEffect(() => {
    dispatch(show(id as string));
  }, [id, dispatch]);

  useEffect(() => {
    return function cleanup() {
      dispatch(clearFields());
    };
  }, [dispatch]);

  type ContentProps = {
    children: React.ReactNode;
  };

  const Content = ({ children }: ContentProps) => {
    return <div className={classes.content}>{children}</div>;
  };

  type PanelProps = {
    children: React.ReactNode;
    name: string;
  };

  const Panel = ({ children, name }: PanelProps) => {
    return (
      <div
        role="tabpanel"
        hidden={query.section !== name}
        id={`tabpanel-${name}`}
        aria-labelledby={`tab-${name}`}
      >
        {query.section === name && <>{children}</>}
      </div>
    );
  };

  return (
    <Main
      error={error}
      title={"Ticket " + (id || "")}
      loading={loading || !ticket.id}
    >
      <Paper style={{ width: "100%" }}>
        <Tabs
          indicatorColor="primary"
          onChange={(event, value) => setQuery({ section: value })}
          value={query.section}
        >
          <Tab label="Ticket info" value="info" />
          <Tab label="Comments" value="comments" />
          {accountSettings.affinity_support_permission_view_sla === "1" && (
            <Tab label="SLA info" value="sla" />
          )}
        </Tabs>

        <Divider className={classes.divider} />

        <Content>
          <Details ticket={ticket} />

          <Divider className={`${classes.divider} ${classes.divider2}`} />

          <Panel name="info">
            <Info ticket={ticket} />
          </Panel>
          <Panel name="comments">
            <Comments />
          </Panel>

          {accountSettings.affinity_support_permission_view_sla === "1" && (
            <Panel name="sla">
              <SLA ticket={ticket} />
            </Panel>
          )}
        </Content>
      </Paper>
    </Main>
  );
}
