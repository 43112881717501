import React from "react";

import { AppBrand, useAuth } from "@akj-dev/affinity-platform";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableMeta,
  MUIDataTableOptions,
  // Responsive
} from "mui-datatables";

import { Box, Button, CircularProgress, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Link } from "react-router-dom";

import MouseOverPopover from "../../../../components/MouseOverPopover";

import { constants, functions } from "../../../../helpers";
import { Ticket } from "../../../../types";

import { Query, SortDirection } from "../../types";

const useStyles = makeStyles((theme) => ({
  table: {
    "& th": {
      whiteSpace: "nowrap",
    },
    "& td": {
      whiteSpace: "nowrap",
    },
  },
}));

type Props = {
  count: number;
  data: Array<Ticket>;
  loading: boolean;
  query: Query;
  setQuery: (query: Partial<Query>) => void;
};

export default function Component({
  count,
  data,
  loading,
  query,
  setQuery,
}: Props) {
  const classes = useStyles();
  const { accountSettings } = useAuth();

  // Check if "sort" is equal with the given "columnName"
  // and return the value of "order" variable when it's true,
  // and "none" otherwise
  const getSortDirection = (columnName: string): SortDirection =>
    query.sort === columnName ? query.order : "none";

  let columns: MUIDataTableColumnDef[] = [
    {
      name: "case_number",
      label: "ID",
      options: {
        setCellHeaderProps: () => ({
          style: { paddingLeft: 30 },
        }),
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const row = data[tableMeta.rowIndex];

          return (
            <Button
              variant="text"
              color="primary"
              size="small"
              component={Link}
              to={"/support/tickets/" + row.case_number}
            >
              {value}
            </Button>
          );
        },
        filter: false,
        sortDirection: getSortDirection("case_number"),
      },
    },
    {
      name: "account_name",
      label: "Company Name",
      options: {
        filter: false,
        sortDirection: getSortDirection("account_name"),
      },
    },
    ...(window.location.hostname.includes(AppBrand.DWS)
      ? [
          {
            name: "cust_ref_no",
            label: "Customer Reference",
            options: {
              filter: false,
              sortDirection: getSortDirection("cust_ref_no"),
            },
          },
        ]
      : []),
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const found = constants.ticketStatusMap.find(
            (item) => item.value === value
          );

          return found ? found.label : value;
        },
        filter: false,
        sortDirection: getSortDirection("status"),
      },
    },
    {
      name: "type",
      label: "Ticket Type",
      options: {
        filter: false,
        sortDirection: getSortDirection("type"),
      },
    },
    {
      name: "name",
      label: "Category",
      options: {
        filter: false,
        sortDirection: getSortDirection("name"),
      },
    },
    {
      name: "product_type",
      label: "Product",
      options: {
        filter: false,
        sortDirection: getSortDirection("product_type"),
      },
    },
    ...(window.location.hostname.includes(AppBrand.DWS)
      ? [
          {
            name: "clis",
            label: "CLI",
            options: {
              customBodyRender: (
                value: string[],
                tableMeta: MUIDataTableMeta
              ) => {
                return value.join(", ");
              },
              filter: false,
              sortDirection: getSortDirection("clis"),
            },
          },
        ]
      : []),
    {
      name: "created_by_for_display",
      label: "Created By",
      options: {
        filter: false,
        sortDirection: getSortDirection("created_by_for_display"),
      },
    },
    {
      name: "date_entered",
      label: "Date Created",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          return functions.formattedDate(value);
        },
        filter: false,
        sortDirection: getSortDirection("date_entered"),
      },
    },
    {
      name: "date_resolved",
      label: "Date Resolved",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          return functions.formattedDate(value);
        },
        filter: false,
        sortDirection: getSortDirection("date_resolved"),
      },
    },
    {
      name: "date_modified",
      label: "Last Update",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          return functions.formattedDate(value);
        },
        filter: false,
        sortDirection: getSortDirection("date_modified"),
      },
    },
  ];

  if (accountSettings.affinity_support_permission_view_sla === "1") {
    columns.push({
      name: "sla.calendar.name",
      label: "SLA",
      options: {
        customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
          const row = data[tableMeta.rowIndex];

          return (
            <MouseOverPopover text={value}>
              <div
                style={{ maxWidth: 400 }}
                dangerouslySetInnerHTML={{
                  __html: row.sla?.calendar?.description_as_html || "N/A",
                }}
              />
            </MouseOverPopover>
          );
        },
        filter: false,
        sortDirection: getSortDirection("sla"),
        sort: false,
      },
    });
  }

  const options: MUIDataTableOptions = {
    // feature toggles
    download: false,
    filter: false,
    print: false,
    rowHover: true,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    elevation: 1,
    selectToolbarPlacement: "none",

    // responsive: "scroll" as Responsive,
    serverSide: true,

    rowsPerPage: query.limit,
    page: query.page - 1,
    count: count,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          return setQuery({ page: tableState.page + 1 });
        case "changeRowsPerPage":
          return setQuery({
            limit: tableState.rowsPerPage,
            page: 1,
          });
        default:
          break;
      }
    },

    onColumnSortChange: (changedColumn, direction) => {
      setQuery({
        page: 1,
        sort: changedColumn,
        order: direction,
      });
    },

    setTableProps: () => ({
      className: classes.table,
      size: "small",
    }),
  };

  return (
    <MUIDataTable
      title={
        <Box display="flex" alignItems="center">
          <Box marginRight={1}>
            <Typography variant="subtitle1">Tickets</Typography>
          </Box>
          {loading && <CircularProgress size={24} />}
        </Box>
      }
      data={data}
      columns={columns}
      options={options}
    />
  );
}
