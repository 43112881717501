import { getErrorMessage } from "@akj-dev/affinity-platform";
import { format, parseISO } from "date-fns";

export const dateOrNull = (date: string) => (date ? parseISO(date) : null);

export function formattedDate(
  dateString: string,
  pattern: string = "dd/MM/yyyy"
): string {
  try {
    const date = parseISO(dateString);

    return format(date, pattern);
  } catch (e) {
    console.log(getErrorMessage(e));
  }

  return dateString;
}

export function formattedDateTime(
  dateString: string,
  pattern: string = "HH:mm dd/MM/yyyy"
): string {
  try {
    const date = parseISO(dateString);

    return format(date, pattern);
  } catch (e) {
    console.log(getErrorMessage(e));
  }

  return dateString;
}

// will convert
// 2d 3h 0m to 2 days and 3 hours
// 0d 3h 0m to 3 hours
export function humanReadableSlaDuration(duration: string) {
  const clean = (duration || "")
    .replace("-", "")
    // remove "0d "
    .replace(/^0d\s/, "")
    // remove "0h "
    .replace(/^(\d+d)?(\s)?0h\s/, "$1")
    // remove "0m"
    .replace(/^(\d+d)?(\s)?(\d+h)?(\s)?0m/, "$1$2$3")
    .replace("d", " days")
    .replace("h", " hours")
    .replace("m", " minutes")
    // replace space with "and"
    .replace(/\s(\d+)/g, " and $1");

  return clean;
}
