import * as actions from "./actionTypes";

export const searchAccounts = (query: string) => {
  return { type: actions.SEARCH_ACCOUNTS, payload: { query } };
};

export const searchCLIs = (
  account_id: string,
  product_type: string,
  query: string,
  with_info?: number
) => {
  return {
    type: actions.SEARCH_CLIS,
    payload: { account_id, product_type, query, with_info },
  };
};

export const searchContacts = (query: string) => {
  return { type: actions.SEARCH_CONTACTS, payload: { query } };
};
