import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import {
  Autocomplete,
  FilterBar,
  FilterBarPreviewProps,
  TextField,
  DatePicker,
} from "../../../../components/filters";
import { Query } from "../../types";
import { statuses, types } from "../../../../helpers/constants";
import { formattedDate } from "../../../../helpers/functions";
import {
  useAccounts,
  useCategories,
  useProducts,
  useUsers,
} from "../../../../hooks/tickets";

interface Props {
  query: Partial<Query>;
  setQuery: (query: Partial<Query>) => void;
  resetQuery: () => void;
}

function Component({ query, setQuery, resetQuery }: Props) {
  const [accountsQuery, setAccountsQuery] = useState("");
  const accounts = useAccounts(accountsQuery);
  const [usersQuery, setUsersQuery] = useState("");
  const users = useUsers(usersQuery);
  const products = useProducts(query.type);
  const categories = useCategories(query.type, query.product_type);

  let preview: FilterBarPreviewProps[] = [];

  if (query.case_number) {
    preview.push({
      label: "Ticket Number",
      values: [query.case_number],
      onDelete: () => setQuery({ case_number: undefined }),
    });
  }

  if (query.account_id) {
    preview.push({
      label: "Company Name",
      values: [query.account_name ?? query.account_id],
      onDelete: () =>
        setQuery({ account_id: undefined, account_name: undefined }),
    });
  }

  if (query.status) {
    preview.push({
      label: "Status",
      values: [query.status],
      onDelete: () => setQuery({ status: undefined }),
    });
  }

  if (query.type) {
    preview.push({
      label: "Ticket Type",
      values: [query.type],
      onDelete: () => setQuery({ type: undefined }),
    });
  }

  if (query.product_type) {
    preview.push({
      label: "Product",
      values: [query.product_type],
      onDelete: () => setQuery({ product_type: undefined }),
    });
  }

  if (query.subtype) {
    preview.push({
      label: "Category",
      values: [query.subtype],
      onDelete: () => setQuery({ subtype: undefined }),
    });
  }

  if (query.created_after) {
    preview.push({
      label: "Date Created from",
      values: query?.created_after ? [formattedDate(query?.created_after)] : [],
      onDelete: () => setQuery({ created_after: undefined }),
    });
  }

  if (query.created_before) {
    preview.push({
      label: "Date Created to",
      values: query?.created_before
        ? [formattedDate(query?.created_before)]
        : [],
      onDelete: () => setQuery({ created_before: undefined }),
    });
  }

  if (query.resolved_after) {
    preview.push({
      label: "Date Resolved from",
      values: query?.resolved_after
        ? [formattedDate(query?.resolved_after)]
        : [],
      onDelete: () => setQuery({ resolved_after: undefined }),
    });
  }

  if (query.resolved_before) {
    preview.push({
      label: "Date Resolved to",
      values: query?.resolved_before
        ? [formattedDate(query?.resolved_before)]
        : [],
      onDelete: () => setQuery({ resolved_before: undefined }),
    });
  }

  if (query.created_by) {
    preview.push({
      label: "Created By",
      values: query?.created_by ? [formattedDate(query?.created_by)] : [],
      onDelete: () => setQuery({ created_by: undefined }),
    });
  }

  const onReset = useCallback(() => {
    resetQuery();
  }, [resetQuery]);

  return (
    <>
      <FilterBar onReset={onReset} preview={preview} title="Filter">
        <Box overflow="auto" display="flex">
          <TextField
            label="Ticket Number"
            onApply={(case_number: any) => setQuery({ case_number })}
            value={query?.case_number ?? ""}
            multiple={false}
            type="number"
          />
          <Autocomplete
            defaultValue={query.account_id}
            label="Company Name"
            onSelect={(account) =>
              setQuery({ account_id: account.id, account_name: account.name })
            }
            onSearch={(query) => setAccountsQuery(query)}
            options={accounts.data?.data?.results || []}
            multiple={false}
            loading={accounts.isLoading || accounts.isLoading}
            labelFrom="name"
            valueFrom="id"
          />
          <Autocomplete
            defaultValue={query.status}
            label="Status"
            onSelect={(status) => setQuery({ status })}
            options={statuses.map((status) => status)}
            multiple={false}
          />
          <Autocomplete
            defaultValue={query.type}
            label="Ticket Type"
            onSelect={(type) => setQuery({ type })}
            options={types.map((type) => type)}
            multiple={false}
          />
          <Autocomplete
            defaultValue={query.product_type}
            label="Product"
            onSelect={(product_type) => setQuery({ product_type })}
            options={products.data?.results || []}
            multiple={false}
          />
          <Autocomplete
            defaultValue={query.subtype}
            label="Category"
            onSelect={(subtype) => setQuery({ subtype })}
            options={categories.data?.results || []}
            multiple={false}
          />
          <DatePicker
            label="Date Created from"
            onApply={(created_after) => setQuery({ created_after })}
            value={query?.created_after}
            maxDate={query?.created_before}
          />
          <DatePicker
            label="Date Created to"
            onApply={(created_before) => setQuery({ created_before })}
            value={query?.created_before}
            minDate={query?.created_after}
          />
          <DatePicker
            label="Date Resolved from"
            onApply={(resolved_after) => setQuery({ resolved_after })}
            value={query?.resolved_after}
            maxDate={query?.resolved_before}
          />
          <DatePicker
            label="Date Resolved to"
            onApply={(resolved_before) => setQuery({ resolved_before })}
            value={query?.resolved_before}
            minDate={query?.resolved_after}
          />
          <Autocomplete
            defaultValue={query.account_id}
            label="Created by"
            onSelect={(created_by) => {
              setQuery({ created_by: created_by.name });
            }}
            onSearch={(query) => setUsersQuery(query)}
            options={users.data?.data?.results || []}
            multiple={false}
            loading={users.isLoading || users.isLoading}
            labelFrom="name"
            valueFrom="name"
          />
        </Box>
      </FilterBar>
    </>
  );
}

export default Component;
