import { all, call } from "redux-saga/effects";

import * as attachments from "./attachments/sagas";
import * as autocomplete from "./autocomplete/sagas";
import * as comments from "./comments/sagas";
import * as dashboard from "./dashboard/sagas";
import * as tickets from "./tickets/sagas";

export default function* rootSaga() {
  yield all([
    call(attachments.watchFetch),

    call(autocomplete.watchSearchAccounts),
    call(autocomplete.watchSearchCLIs),
    call(autocomplete.watchSearchContacts),

    call(comments.watchCreate),
    call(comments.watchFetch),

    call(dashboard.watchFetch),

    call(tickets.watchCreate),
    call(tickets.watchPaginate),
    call(tickets.watchSetFields),
    call(tickets.watchShow),
  ]);
}
