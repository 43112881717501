import u from "updeep";

import * as actions from "./actionTypes";

const initialState = {
  count: 0,
  data: [],
  error: "",
  fields: {
    subject: "Other",
    description: "",
    attachments: [],
    acknowledge_case: false,
  },
  loading: true,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.CREATE:
      return u(
        {
          error: "",
          loading: true,
        },
        state
      );
    case actions.CREATE_OK:
      return u(
        {
          fields: initialState.fields,
        },
        state
      );
    case actions.CREATE_FAILED:
      return u(
        {
          ...action.payload,
          loading: false,
        },
        state
      );
    case actions.FETCH:
      return u(
        {
          error: "",
          loading: true,
        },
        state
      );
    case actions.FETCH_OK:
      return u(
        {
          error: "",
          loading: false,
          data: action.payload.data.results || [],
          count: parseInt(action.payload.data.meta.total_count) || 0,
        },
        state
      );
    case actions.FETCH_FAILED:
      return u(
        {
          ...action.payload,
          loading: false,
        },
        state
      );
    case actions.SET_FIELDS:
      return u(
        {
          fields: {
            ...action.fields,
          },
        },
        state
      );
    default:
      return state;
  }
};

export default reducer;
