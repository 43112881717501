import React, { useEffect, useState } from "react";
import { Box, Chip, TextField as ParentTextField } from "@mui/material";
import Wrap from "./Wrap";

export interface TextFieldProps {
  label: string;
  onApply: (values: any[]) => void;
  value?: string;
  values?: string[];
  type?: string;

  [key: string]: any;
}

export function TextField(props: TextFieldProps) {
  const [value, setValue] = useState<any>(props?.value ?? "");
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    setValue(props?.value ?? "");
  }, [props.value]);

  useEffect(() => {
    setValues(props?.values ?? []);
  }, [props.values]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.type === "number" && parseInt(event.target.value) < 1) {
      setValue("1");
    } else {
      setValue(event.target.value);
    }
  };

  return (
    <Wrap
      {...props}
      onApply={() => {
        if (props.multiple) {
          let update = values;
          if (value) {
            update.push(value);
          }
          props.onApply(update);
        } else {
          props.onApply(value);
        }
        setValue("");
      }}
    >
      <ParentTextField
        autoFocus
        label={props.label}
        onChange={handleChange}
        size="small"
        value={value}
        variant="outlined"
        type={props.type ?? "text"}
      />

      {props.multiple && values.length > 0 && (
        <Box display="flex" flexWrap="wrap" mt={1}>
          {values.map((value, index) => (
            <Box key={value} mb={0.5} mr={0.5}>
              <Chip
                label={value}
                onDelete={() =>
                  setValues(values.filter((existing) => existing !== value))
                }
                size="small"
              />
            </Box>
          ))}
        </Box>
      )}
    </Wrap>
  );
}
