import React, { ReactNode } from "react";

import { Alert } from "@akj-dev/shared-components";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  children: ReactNode;
  error: string;
  loading: boolean;
}

function Loading({ children, loading, error }: Props) {
  if (error) {
    return <Alert message={error} type="error" />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return <>{children}</>;
}

export default Loading;
