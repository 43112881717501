import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Tooltip,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(1),
  },
  cardHeaderAction: {
    marginTop: 0,
  },
  cardHeaderTitle: {
    marginLeft: theme.spacing(0.75),
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0,

    "&:nth-child(3)": {
      paddingBottom: theme.spacing(1),
    },
  },
  previewBar: {
    border: "1px solid",
    display: "flex",
    flexWrap: "wrap",
    gap: "6px",
    // overflow: "auto",

    borderColor: theme?.palette?.grey?.[100],
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
  },
}));

export interface FilterBarPreviewProps {
  label: string;
  max?: number;
  values?: string[];
  onDelete: () => void;
}

export interface FilterBarProps {
  children: React.ReactNode;
  closed?: boolean;
  onReset?: () => void;
  preview: FilterBarPreviewProps[];
  previewOnClose?: boolean;
  title?: React.ReactNode;
}

export function FilterBar({
  children,
  closed = false,
  onReset = () => null,
  preview,
  previewOnClose,
  title,
}: FilterBarProps) {
  const classes = useStyles();
  const [isClosed, toggleClosed] = useState(closed);

  return (
    <Card className={classes.card}>
      <CardHeader
        action={
          <Box display="flex">
            <Box ml={1}>
              <Button
                color="primary"
                onClick={onReset}
                size="small"
                variant="outlined"
              >
                Reset
              </Button>
            </Box>

            <Box ml={0.5}>
              <Button
                color="inherit"
                endIcon={isClosed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                onClick={() => toggleClosed(!isClosed)}
                size="small"
              >
                {isClosed ? "Show" : "Hide"}
              </Button>
            </Box>
          </Box>
        }
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeaderTitle,
        }}
        title={title}
      />
      {!isClosed && (
        <CardContent className={classes.cardContent}>
          <Box display="flex">{children}</Box>
        </CardContent>
      )}
      {((isClosed && previewOnClose) || !isClosed) && preview?.length > 0 && (
        <CardContent className={classes.cardContent}>
          <Box
            className={classes.previewBar}
            mt={0.5}
            mr={0.5}
            ml={0.5}
            p={0.3}
          >
            {preview?.map((item, index) => (
              <Tooltip
                key={item.label}
                title={`${item.label}: ${item.values?.join(", ") ?? ""}`}
              >
                <Chip
                  label={`${item.label}: ${
                    item.max && (item.values?.length ?? 0) > item.max
                      ? `${item.values?.length} selected`
                      : item.values?.join(", ")
                  }`}
                  size="small"
                  variant="outlined"
                  onDelete={item.onDelete}
                />
              </Tooltip>
            ))}
          </Box>
        </CardContent>
      )}
    </Card>
  );
}
