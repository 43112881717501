import React from "react";
import { Badge, Box, Button, Tooltip, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileItem from "./FileItem";

interface Props {
  files: Array<File>;
  onChange: (files: Array<File | null>) => void;
  label?: string;
  multiple?: boolean;
  variant?: "text" | "outlined" | "contained";
  uploading?: boolean;
}

function Component({
  files,
  onChange,
  label,
  multiple,
  variant,
  uploading = false,
}: Props) {
  const handleChange = (fileList: FileList | null) => {
    let addedFiles = files ? [...files] : [];

    if (fileList) {
      for (var i = 0; i < fileList.length; ++i) {
        const file = fileList.item(i);
        if (file) addedFiles.push(file);
      }
    }

    onChange(addedFiles);
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    onChange(newFiles);
  };

  const Preview = () => {
    if (!files.length) {
      return <AttachFileIcon />;
    }

    const title = files.map((file: File) => (
      <Typography key={`title-${file.name}`} variant="body2">
        {file && file.name}
      </Typography>
    ));

    return (
      <Tooltip title={title}>
        <Badge badgeContent={files.length} color="primary">
          <AttachFileIcon />
        </Badge>
      </Tooltip>
    );
  };

  return (
    <>
      <Box marginRight="12px" marginBottom="12px" display="inline-block">
        <Button
          component="label"
          size="medium"
          startIcon={<Preview />}
          variant={variant || "text"}
          disabled={uploading}
        >
          {label || "Attach files"}
          <input
            hidden
            multiple={multiple}
            onChange={(e) => handleChange(e.target.files)}
            type="file"
            onClick={(event) => {
              (event.target as HTMLInputElement).value = "";
            }}
          />
        </Button>
      </Box>
      {files?.map((file, index) => (
        <FileItem
          file={file}
          index={index}
          handleRemoveFile={handleRemoveFile}
          key={`fileitem-${file.name}`}
          uploading={uploading}
        />
      ))}
    </>
  );
}

export default Component;
