import { getJSON } from "./";

export function accounts({ query, limit = 10 }) {
  return getJSON("Account/AutoComplete", {
    with_ban: 1,
    name: query,
    limit,
  });
}

export function clis({
  account_id,
  product_type,
  query,
  with_info = 0,
  limit = 1000,
}) {
  return getJSON("CLIs/AutoComplete", {
    account_id,
    product_type,
    query,
    with_info,
    limit,
  });
}

export function contacts({ query, limit = 10 }) {
  return getJSON("Affinity/Contacts/AutoComplete", {
    primary_contact_first: 1,
    query,
    limit,
  });
}

export function users({ query, limit = 10 }) {
  return getJSON("ExternalUsers/AutoComplete", {
    with_ban: 1,
    name: query,
    limit,
  });
}
