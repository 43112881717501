import React from "react";
import queryString from "query-string";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";

import { Query, Stats } from "../types";

import Donut from "./Donut";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover $actions": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  header: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  content: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    display: "flex",
    justifyContent: "left",
  },
  actions: {
    transition: `background-color ${theme.transitions.easing.easeInOut} 0.${theme.transitions.duration.standard}s`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  link: {
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    textDecoration: "none",
    width: "100%",
  },
  in_sla: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    marginTop: theme.spacing(0.5),
  },
  out_sla: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    marginTop: theme.spacing(0.5),
  },
}));

interface Props {
  info: Stats;
  query: Query;
}

function Component({ info, query }: Props) {
  const classes = useStyles();

  const filter = {
    ...query,
    sla: info.in_sla ? "In" : "Out",
    type: info.type,
  };

  return (
    <Card
      raised
      className={classes.root}
      data-in-sla={info.in_sla}
      data-type={info.type}
    >
      <CardHeader
        action={
          <Chip
            className={info.in_sla ? classes.in_sla : classes.out_sla}
            label={info.in_sla ? "In SLA" : "Out SLA"}
            size="small"
          />
        }
        className={classes.header}
        title={
          info.type +
          "s" +
          " (" +
          (query.open === "1"
            ? "Open"
            : query.open === "0"
            ? "Closed"
            : "All") +
          ")"
        }
      />
      <CardContent className={classes.content}>
        <Donut filter={filter} info={info} />
      </CardContent>
      <CardActions className={classes.actions}>
        <Link
          className={classes.link}
          to={"/support/tickets?" + queryString.stringify(filter)}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <div>View all</div>
            <ChevronRightIcon fontSize="small" />
          </Box>
        </Link>
      </CardActions>
    </Card>
  );
}

export default Component;
