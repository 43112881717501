import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import { Alert } from "@akj-dev/shared-components";

import {
  Box,
  Button,
  CircularProgress,
  List,
  Typography,
  ListItem,
  Divider,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Link } from "react-router-dom";

import Ticket from "./components/Ticket";

import { paginate } from "../../../store/tickets/actions";

import { RootState } from "../../../types";
import { Query, Status } from "../types";

import { constants } from "../../../helpers";
import { Ticket as TicketType } from "../../../types";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: `0 ${theme.spacing(2)}`,
  },
  list: {
    width: "100%",
    minWidth: 225,
  },
  root: {
    flex: 1,
  },
  title: {
    width: "100%",

    "& span": {
      float: "right",
    },
  },
}));

const ErrorIndicator = ({ error }: { error: string }) => {
  if (!error) {
    return <></>;
  }

  return (
    <ListItem disableGutters>
      <Alert message={error} type="error" />
    </ListItem>
  );
};

const LoadingIndicator = ({ loading }: { loading: boolean }) => {
  if (!loading) {
    return <></>;
  }

  return (
    <ListItem disableGutters>
      <Button
        disabled={true}
        fullWidth
        size="small"
        startIcon={<CircularProgress size={24} />}
        variant="text"
      >
        Loading
      </Button>
    </ListItem>
  );
};

type Props = {
  query: Query;
  resetQuery: () => void;
  setQuery: (query: Partial<Query>) => void;
  status: Status;
};

function Component({ query, resetQuery, setQuery, status }: Props) {
  const classes = useStyles();

  const [local, setLocal] = useState({
    append: false,
    limit: query.limit || constants.limit.kanban,
    page: 1,
  });

  const { count, data, error, loading } = useSelector(
    (state: RootState) => state.tickets[status]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const { limit, page } = local;

    dispatch(paginate(status, { ...query, limit, page, status }, local.append));
  }, [query, local, status, dispatch]);

  const LoadMore = () => {
    // Are there more items?
    if (!loading && local.page * local.limit < count) {
      // Do we want to allow the user to load more?
      if (data.length < constants.limit.kanbanMaxItems) {
        return (
          <ListItem disableGutters>
            <Button
              fullWidth
              onClick={() =>
                setLocal({ ...local, append: true, page: local.page + 1 })
              }
              size="small"
              variant="text"
            >
              Load more
            </Button>
          </ListItem>
        );
      }

      const { limit, page } = local;

      // Inform the user that we alreay displayed the maximum number of tickets on Kanban view
      // And show a button that will take them to Table view on the desired page
      return (
        <>
          <ListItem disableGutters>
            <Typography align="center" variant="caption">
              A maximum of {constants.limit.kanbanMaxItems} items can be viewed
              on Kanban view.
            </Typography>
          </ListItem>
          <ListItem disableGutters>
            <Button
              component={Link}
              fullWidth
              size="small"
              to={
                "/support/tickets?" +
                queryString.stringify({
                  limit,
                  page: page + 1,
                  status,
                })
              }
              variant="text"
            >
              View more on Table view
            </Button>
          </ListItem>
        </>
      );
    }

    return <></>;
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={1}
      className={classes.root}
    >
      <List className={classes.list} data-board={status}>
        <ListItem disableGutters>
          <Typography className={classes.title} variant="h3">
            {status} <span>{count}</span>
          </Typography>
        </ListItem>

        {data.map((ticket: TicketType, i: number) => (
          <ListItem key={ticket.id} disableGutters>
            <Ticket ticket={ticket} />
          </ListItem>
        ))}

        <LoadingIndicator loading={loading} />
        <ErrorIndicator error={error} />
        <LoadMore />
      </List>

      <Divider
        className={classes.divider}
        orientation="vertical"
        flexItem
        light
      />
    </Box>
  );
}

export default Component;
