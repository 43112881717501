import React from "react";

import {
  formattedDateTime,
  humanReadableSlaDuration,
} from "../../../../helpers/functions";

import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  AccessTime as AccessTimeIcon,
  Today as TodayIcon,
} from "@mui/icons-material";

import { Ticket } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

interface Props {
  ticket: Ticket;
}

export default function Component({ ticket }: Props) {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Typography paragraph={true} variant="h4">
          {ticket.sla?.calendar?.name}
        </Typography>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: ticket.sla?.calendar?.description_as_html,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography paragraph={true} variant="caption">
              <AccessTimeIcon className={classes.icon} /> Response Time
            </Typography>
            <Typography paragraph={true} variant="body2">
              {humanReadableSlaDuration(ticket.sla?.duration_human_readable)}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography paragraph={true} variant="caption">
              <TodayIcon className={classes.icon} /> Expiry Date
            </Typography>
            <Typography paragraph={true} variant="body2">
              {formattedDateTime(ticket.sla?.target_time)}
            </Typography>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
