import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "./components/Form";
import Main from "../../../layout/Main";

import {
  searchAccounts,
  searchCLIs,
  searchContacts,
} from "../../../store/autocomplete/actions";

import {
  clearFields,
  create,
  resetError,
  setFields,
} from "../../../store/tickets/actions";

import { RootState } from "../../../types";
import {
  ArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { useAuth } from "@akj-dev/affinity-platform";

export default function NewTicket() {
  const dispatch = useDispatch();

  const dispatchSearchContacts = useCallback(
    (query: string) => dispatch(searchContacts(query)),
    [dispatch]
  );

  const { accountMeta } = useAuth();

  const { error, fields, loading } = useSelector(
    (state: RootState) => state.tickets
  );

  const { accounts, clis, contacts } = useSelector(
    (state: RootState) => state.autocomplete
  );

  const [query] = useQueryParams({
    account_id: withDefault(
      StringParam,
      accountMeta.type === "account" ? accountMeta.id : ""
    ),
    product_type: withDefault(StringParam, ""),
    "clis[]": withDefault(ArrayParam, []),
    subtype: withDefault(StringParam, ""),
    type: withDefault(StringParam, "Service Request"),
    name: withDefault(StringParam, ""),
  });

  useEffect(() => {
    dispatch(setFields(query));

    if (query.account_id) {
      dispatch(searchAccounts(query.account_id));

      if (query.product_type && query["clis[]"]?.length) {
        dispatch(searchCLIs(query.account_id, query.product_type, "", 1));
      }
    }
  }, [query, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetError());
      dispatch(clearFields());
    };
  }, [dispatch]);

  return (
    <Main error={error} title="New Ticket" loading={loading}>
      <Form
        accounts={accounts}
        searchAccounts={(query: string) => dispatch(searchAccounts(query))}
        clis={clis}
        fields={fields}
        contacts={contacts}
        searchContacts={dispatchSearchContacts}
        setFields={(fields: any) => dispatch(setFields(fields))}
        onSubmit={(fields: any) => dispatch(create(fields))}
      />
    </Main>
  );
}
