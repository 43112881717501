import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { NumberParam, withDefault, useQueryParams } from "use-query-params";

import { Grid, Typography } from "@mui/material";

import Form from "./components/Form";
import Table from "./components/Table";
import Loading from "../../layout/Loading";

import { create, fetch, setFields } from "../../store/comments/actions";

import { constants } from "../../helpers";
import { LooseObject, RootState } from "../../types";

export default function Comments() {
  const { count, data, error, fields, loading } = useSelector(
    (state: RootState) => state.comments
  );

  const { ticket } = useSelector((state: RootState) => state.tickets);

  const dispatch = useDispatch();

  const { id } = useParams<LooseObject>();

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, constants.limit.comments),
    page: withDefault(NumberParam, 1),
  });

  useEffect(() => {
    dispatch(fetch(id as string, query));
  }, [id, query, dispatch]);

  return (
    <Loading error={error} loading={loading}>
      <Grid container spacing={1}>
        {count > 0 && (
          <Grid item xs={8}>
            <Table
              count={count}
              data={data}
              limit={query.limit}
              page={query.page}
              setPagination={setQuery}
            />
          </Grid>
        )}
        <Grid item xs={count > 0 ? 4 : 12}>
          <Typography paragraph={true} variant="h4">
            Add Comment
          </Typography>

          <Form
            fields={fields}
            onSubmit={(fields: any) => dispatch(create(id as string, fields))}
            update={(fields: any) => dispatch(setFields(fields))}
            ticketStatus={ticket.status}
          />
        </Grid>
      </Grid>
    </Loading>
  );
}
