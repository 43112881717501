import { call, put, takeLatest } from "redux-saga/effects";

import * as actions from "./actionTypes";
import * as api from "../../api/attachments";
import { getErrorMessage } from "@akj-dev/affinity-platform";

function* fetch(action: any) {
  try {
    yield call(api.download, action.attachment);
    yield put({ type: actions.FETCH_OK, payload: action.attachment });
  } catch (error) {
    yield put({
      type: actions.FETCH_FAILED,
      payload: {
        error: "There was a problem fetching ticket details. Please try again.",
      },
    });

    console.log("fetch ticket error:", getErrorMessage(error));
  }
}

export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
}
