import { getJSON, postJSON, multiPartPostJSON } from "./";
import { constants } from "../helpers";

export function categories(type, product) {
  return getJSON("Case/Categories/Search", { type, product });
}

export function create(params) {
  if (params.attachments?.length) {
    return multiPartPostJSON("Cases/Create", {
      ...params,
      assign_to_user_name: "Distribution.Mobile",
      external_source: "Affinity Support",
    });
  }

  return postJSON("Cases/Create", {
    ...params,
    assign_to_user_name: "Distribution.Mobile",
    external_source: "Affinity Support",
  });
}

export function paginate(query) {
  // Because we have a map for the priority field,
  // we need to override the value chosen by the user with the corresponding value in the map
  const priority = constants.priorities.find(
    (item) => item.label === query.priority
  );

  // Because we have a map for the status field,
  // we need to override the value chosen by the user with the corresponding value in the map
  const statuses = constants.ticketStatusMap
    .filter((item) => item.label === query.status)
    .map((item) => item.value);

  return getJSON("Cases/Search", {
    ...query,
    include_children_cases: 1,
    priority: priority ? priority.value : undefined,
    status: undefined,
    statuses: statuses,
    // Restrict to only "Service Request", "Incident" cases
    types: ["Service Request", "Incident"],
  });
}

export function products(type) {
  return getJSON("Case/Products/Search", { type });
}

export function show(id) {
  return getJSON("Cases/View/" + id);
}

export function subCategories(type, product, category) {
  return getJSON("Case/SubCategories/Search", { type, product, category });
}

export function types() {
  return getJSON("Case/Types/Search");
}
