import u from "updeep";

import * as actions from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  stats: [],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.FETCH:
      return u(
        {
          error: "",
          loading: true,
        },
        state
      );
    case actions.FETCH_OK:
      return u(
        {
          error: "",
          loading: false,
          stats: action.payload,
        },
        state
      );
    case actions.FETCH_FAILED:
      return u(
        {
          ...action.payload,
          loading: false,
        },
        state
      );
    default:
      return state;
  }
};

export default reducer;
