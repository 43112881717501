import React from "react";

import { Grid, Typography } from "@mui/material";

import { Ticket } from "../../../../types";
import { formattedDateTime } from "../../../../helpers/functions";
import { constants } from "../../../../helpers";

interface Props {
  ticket: Ticket;
}

export default function Component({ ticket }: Props) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Grid container spacing={1}>
          <Grid item md={2} sm={6} xs={12}>
            <Typography display="block" variant="caption">
              Status
            </Typography>
            <Typography variant="h4">
              {constants.ticketStatusMap.find(
                (item) => item.value === ticket.status
              )?.label || ticket.status}
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography display="block" variant="caption">
              Ticket Type
            </Typography>
            <Typography variant="h4">{ticket.type}</Typography>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <Typography display="block" variant="caption">
              Product
            </Typography>
            <Typography variant="h4">{ticket.product_type}</Typography>
          </Grid>
          {ticket.product_type !== "None" && (
            <Grid item md={3} sm={6} xs={12}>
              <Typography display="block" variant="caption">
                Identifier
              </Typography>
              <Typography variant="h4">{ticket.clis.join(", ")}</Typography>
            </Grid>
          )}
          <Grid item md={2} sm={6} xs={12}>
            <Typography display="block" variant="caption">
              Opened by
            </Typography>
            <Typography variant="h4">
              {" "}
              {ticket.created_by_for_display}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>
            <Typography display="block" variant="caption">
              Opened
            </Typography>
            <Typography variant="h4">
              {formattedDateTime(ticket.date_entered)}
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography display="block" variant="caption">
              Last Modified
            </Typography>
            <Typography variant="h4">
              {formattedDateTime(ticket.date_modified)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
