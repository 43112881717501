import React from "react";
import { AccountSettings, AppBrand } from "@akj-dev/affinity-platform";
import { createRoutingObject } from "@akj-dev/shared-components";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Dashboard from "./screens/Dashboard";
import Tickets from "./screens/Tickets";
import KnowledgeBase from "./screens/KnowledgeBase";
import DWPRedirect from "./screens/DWPRedirect";

export const ROOT_PATH = "/support";

export const getRoutes = (settings: AccountSettings) => {
  let routes = [];

  routes.push({
    path: "/tickets",
    title: "Tickets",
    subject: <Tickets />,
  });

  if (settings.affinity_support_permission_view_dashboard === "1") {
    routes.unshift({
      path: "",
      title: "Dashboard",
      subject: <Dashboard />,
    });
  } else {
    if (window.location.pathname === "/support") {
      window.location.href = "/support/tickets";
    }
  }

  if (settings.affinity_support_permission_view_knowledge_base === "1") {
    routes.push({
      path: "/knowledge-base",
      title: "Knowledge Base",
      subject: <KnowledgeBase />,
    });
  }

  if (window.location.hostname.includes(AppBrand.DWS)) {
    routes.push({
      path: "/dwp",
      title: "Exit Support BETA",
      subject: <DWPRedirect />,
      icon: <KeyboardReturnIcon />,
    });
  }

  return createRoutingObject(routes, ROOT_PATH);
};
