import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import * as actions from "./actionTypes";
import * as autocompleteActions from "../autocomplete/actionTypes";
import * as api from "../../api/tickets";
import { getErrorMessage } from "@akj-dev/affinity-platform";

function* create(action: any): any {
  try {
    let formattedPayload = { ...action.payload };
    if (action.payload.subtype === "Transfer of Ownership") {
      formattedPayload = {
        ...action.payload,
        attachments: [
          ...action.payload.attachments,
          action.payload.transferFiles[0],
          action.payload.transferFiles[1],
        ],
      };
    }

    const response = yield call(api.create, formattedPayload);
    // TODO: uncomment this once a proper redirect is added
    // yield put({ type: actions.CREATE_OK, payload: response });

    // Redirect to Ticket details page
    window.location.href = "/support/tickets/" + response.data.case_number;
  } catch (error) {
    yield put({
      type: actions.CREATE_FAILED,
      payload: {
        error: "There was a problem when saving the ticket. Please try again.",
      },
    });

    console.log("create ticket error:", getErrorMessage(error));
  }
}

function* paginate(action: any): any {
  try {
    const query = action.query;
    const response = yield call(api.paginate, query);

    yield put({
      type: actions.PAGINATE_OK,
      payload: response,
      where: action.where,
      append: action.append,
    });
  } catch (error) {
    yield put({
      type: actions.PAGINATE_FAILED,
      payload: {
        error: "There was a problem fetching tickets. Please try again.",
      },
      where: action.where,
    });

    console.log("paginate tickets error:", getErrorMessage(error));
  }
}

function* setFieldsObserver(action: any) {
  const changes = Object.keys(action.fields);

  // When account id is changed,
  // the clis and contacts needs to be cleared
  if (changes.find((field) => field === "account_id")) {
    yield put({ type: autocompleteActions.RESET_CLIS });
    yield put({ type: autocompleteActions.RESET_CONTACTS });
  }

  // When product type is changed,
  // the clis needs to be cleared
  if (changes.find((field) => field === "product_type")) {
    yield put({ type: autocompleteActions.RESET_CLIS });
  }
}

function* show(action: any): any {
  try {
    const response = yield call(api.show, action.id);
    yield put({ type: actions.SHOW_OK, payload: response });
  } catch (error) {
    yield put({
      type: actions.SHOW_FAILED,
      payload: {
        error: "There was a problem fetching ticket details. Please try again.",
      },
    });

    console.log("show ticket error:", getErrorMessage(error));
  }
}

export function* watchCreate() {
  yield takeLatest(actions.CREATE, create);
}

export function* watchPaginate() {
  yield takeEvery(actions.PAGINATE, paginate);
}

export function* watchSetFields() {
  yield takeLatest(actions.SET_FIELDS, setFieldsObserver);
}

export function* watchShow() {
  yield takeLatest(actions.SHOW, show);
}
