import { call, put, takeLatest } from "redux-saga/effects";

import * as actions from "./actionTypes";
import * as api from "../../api/comments";
import { getErrorMessage } from "@akj-dev/affinity-platform";

function* create(action: any): any {
  try {
    const response = yield call(api.create, action.ticket_id, action.payload);
    yield put({
      type: actions.CREATE_OK,
      ticket_id: action.ticket_id,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_FAILED,
      payload: {
        error: "There was a problem when saving the comment. Please try again.",
      },
    });

    console.log("create comment error:", getErrorMessage(error));
  }
}

function* fetch(action: any): any {
  try {
    const response = yield call(api.paginate, action.ticket_id, action.query);

    yield put({ type: actions.FETCH_OK, payload: response });
  } catch (error) {
    yield put({
      type: actions.FETCH_FAILED,
      payload: {
        error: "There was a problem fetching comments. Please try again.",
      },
    });

    console.log("fetch comments error:", getErrorMessage(error));
  }
}

export function* watchCreate() {
  yield takeLatest(actions.CREATE, create);
}

export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
  yield takeLatest(actions.CREATE_OK, fetch);
}
