import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  AuthCookies,
  getCookieValue,
  useAuth,
} from "@akj-dev/affinity-platform";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .or-chat.or-chat--has-overlay:before": {
      zIndex: "999 !important",
    },
  },
}));

type Props = {
  autoOpen?: boolean;
};

function OneReachChatBot({ autoOpen }: Props) {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const { apiBaseUrl } = useAuth();

  // const chatBotVersion =
  //   process.env.REACT_APP_STAGE !== "production" ? "staging" : "orsolaas";
  // const chatBotId =
  //   process.env.REACT_APP_STAGE !== "production"
  //     ? "DetXEq4dQe-q1e11-ImMDQ/0apok7p"
  //     : "tWCM5ci-QL-EQoshkdMShw/0wn6sfx";
  const chatBotVersion = "staging";
  const chatBotId = "DetXEq4dQe-q1e11-ImMDQ/0apok7p";

  useEffect(() => {
    const head = document.getElementsByTagName("head")[0];
    const link = document.createElement("link");

    link.href = `https://chat.${chatBotVersion}.onereach.ai/lib/richWebChat.css`;
    link.rel = "stylesheet";

    head.appendChild(link);

    const script = document.createElement("script");

    script.src = `https://chat.${chatBotVersion}.onereach.ai/lib/richWebChat.umd.js`;
    script.async = true;
    script.onload = function () {
      setLoaded(true);
    };

    head.appendChild(script);

    return () => {
      head.removeChild(link);
      head.removeChild(script);
    };
  }, [chatBotVersion]);

  const jwt = getCookieValue(AuthCookies.SESSION_TOKEN);

  useEffect(() => {
    if (loaded) {
      // @ts-ignore
      const RWC = window.richWebChat?.default;

      // @ts-ignore
      if (!window.chatbot) {
        // @ts-ignore
        window.chatbot = new RWC({
          container: "#OneReachChatBot", // DOM element selector where chat will be inserted
          chatUrl: `https://chat.${chatBotVersion}.onereach.ai/${chatBotId}`, // url of your chat. required
          position: "bottom-right",
          autoExpandDelay: autoOpen ? 10 : 0,
          inviteMessage: "",
          inviteButton: "",
          inviteImage: undefined,
          showCloseIcon: false,
          inviteTimeout: 5000,
          widgetColor: "#514B65",
          allowStartNewConversation: true,
          thumbLogo: undefined,
          animation: "pulse", // 'pulse', 'ring', 'zoom',
          pageData: {
            apiBaseUrl,
            jwt,
          },
        });
      }

      if (autoOpen) {
        // @ts-ignore
        window.chatbot.openChat();
      }
    }
  }, [loaded, jwt, apiBaseUrl, autoOpen]);

  return <div id="OneReachChatBot" className={classes.root} />;
}

export default OneReachChatBot;
