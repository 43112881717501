import React from "react";

import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

interface Props {
  children: React.ReactElement;
  name?: string;
  trigger: React.ReactNode;
}

export default function Component({ children, trigger, ...props }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const childrenWithNewProps = React.cloneElement(children, {
    onClose: handleClose,
  });

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        color="inherit"
        sx={{
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
        {...props}
      >
        {trigger}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {childrenWithNewProps}
      </Popover>
    </>
  );
}
