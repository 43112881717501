import { constants } from "../../../helpers";

export interface Query {
  case_number?: string | null;
  account_id?: string | null;
  account_name?: string | null;
  status?: Status;
  type?: Type;
  product_type?: ProductType;
  subtype?: string | null;
  cli?: string | null;
  created_after?: string | null;
  created_before?: string | null;
  resolved_after?: string | null;
  resolved_before?: string | null;
  created_by?: string | null;
  sla?: SLAStatus;
  sort?: string;
  order: SortDirection;
  limit?: number;
  page: number;
}

export type ProductType = typeof constants.productTypes[number];
export type SLAStatus = typeof constants.SLAStatuses[number];
export type SortDirection = typeof constants.sortDirections[number];
export type Status = typeof constants.statuses[number];
export type Type = typeof constants.types[number];

export const ProductTypeQueryParam = {
  encode: (value: ProductType) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as ProductType,
};

export const SLAStatusQueryParam = {
  encode: (value: SLAStatus) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as SLAStatus,
};

export const SortDirectionQueryParam = {
  encode: (value: SortDirection) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as SortDirection,
};

export const StatusQueryParam = {
  encode: (value: Status) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as Status,
};

export const TypeQueryParam = {
  encode: (value: Type) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as Type,
};
