import React from "react";
import queryString from "query-string";
import _ from "lodash";

import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
} from "recharts";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { Query, Stats, StatsInfo } from "../types";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "bold",
    fontSize: 45,
  },
  legend: {
    display: "inline-block",
    width: "9rem",
    [theme.breakpoints.up("sm")]: {
      width: "13rem",
    },
    [theme.breakpoints.up("md")]: {
      width: "9rem",
    },
    [theme.breakpoints.up("lg")]: {
      width: "13rem",
    },
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  percent: {
    color: theme.palette.action.disabled,
  },
  root: {
    width: "100%",
    height: 160,

    "& .legend-item-4": {
      display: "none!important",
    },
  },
}));

interface Props {
  filter: Query;
  info: Stats;
}

function Component({ filter, info }: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const COLORS = {
    Critical: theme.palette.error.main,
    Serious: theme.palette.warning.main,
    Medium: theme.palette.success.main,
    Low: theme.palette.info.main,
    Other: theme.palette.text.disabled,
  };

  const renderColorfulLegendText = (value: string, entry: any) => {
    const { payload } = entry;

    return (
      <div className={classes.legend}>
        <Link
          className={classes.link}
          to={
            "/support/tickets?" +
            queryString.stringify({ ...filter, priority: value })
          }
        >
          {value}
        </Link>
        <div style={{ float: "right", display: "inline-block" }}>
          <span className={classes.percent}>
            ({(payload.percent * 100).toFixed(2)}%)
          </span>{" "}
          <b>{payload.value}</b>
        </div>
      </div>
    );
  };

  // Handle only zero values
  let data = info.data.slice();
  const foundNonZeroValue = data.find((item: StatsInfo) => item.value > 0);

  if (!foundNonZeroValue) {
    data.push({ priority: "Other", value: 1 });
  }

  return (
    <div className={classes.root}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            innerRadius={50}
            outerRadius={80}
            dataKey="value"
            nameKey="priority"
          >
            {data.map((item: StatsInfo) => (
              <Cell
                key={`cell-${item.value}-${item.priority}`}
                fill={_.get(COLORS, item.priority)}
                stroke="none"
              />
            ))}
            <Label className={classes.label} width={50} position="center">
              {info.data
                .map((item: StatsInfo) => item.value)
                .reduce((total: number, current: number) => total + current)}
            </Label>
          </Pie>
          <Legend
            align="right"
            iconSize={8}
            iconType="circle"
            layout="vertical"
            verticalAlign="middle"
            wrapperStyle={{ lineHeight: "30px" }}
            formatter={renderColorfulLegendText}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Component;
