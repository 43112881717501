import u from "updeep";

import * as actions from "./actionTypes";

const initialState = {
  accounts: {
    error: "",
    loading: false,
    options: [],
  },

  clis: {
    error: "",
    loading: false,
    options: [],
  },

  contacts: {
    error: "",
    loading: false,
    options: [],
  },
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.SEARCH_ACCOUNTS:
      return u(
        {
          accounts: {
            error: "",
            loading: true,
          },
        },
        state
      );
    case actions.SEARCH_ACCOUNTS_OK:
      return u(
        {
          accounts: {
            error: "",
            loading: false,
            options: action.payload.data.results,
          },
        },
        state
      );
    case actions.SEARCH_ACCOUNTS_FAILED:
      return u(
        {
          accounts: {
            loading: false,
            options: [],
            ...action.payload,
          },
        },
        state
      );
    case actions.SEARCH_CLIS:
      return u(
        {
          clis: {
            error: "",
            loading: true,
          },
        },
        state
      );
    case actions.SEARCH_CLIS_OK:
      return u(
        {
          clis: {
            error: "",
            loading: false,
            options: action.payload.data.results,
          },
        },
        state
      );
    case actions.SEARCH_CLIS_FAILED:
      return u(
        {
          clis: {
            loading: false,
            options: [],
            ...action.payload,
          },
        },
        state
      );
    case actions.RESET_CLIS:
      return u(
        {
          clis: initialState.clis,
        },
        state
      );
    case actions.SEARCH_CONTACTS:
      return u(
        {
          contacts: {
            error: "",
            loading: true,
          },
        },
        state
      );
    case actions.SEARCH_CONTACTS_OK:
      return u(
        {
          contacts: {
            error: "",
            loading: false,
            options: action.payload.data.results,
          },
        },
        state
      );
    case actions.SEARCH_CONTACTS_FAILED:
      return u(
        {
          contacts: {
            loading: false,
            options: [],
            ...action.payload,
          },
        },
        state
      );
    case actions.RESET_CONTACTS:
      return u(
        {
          contacts: initialState.contacts,
        },
        state
      );
    default:
      return state;
  }
};

export default reducer;
