import { constants } from "../../helpers";
import { Stats } from "../../screens/Dashboard/types";

/**
 * Format stats
 *
 * @param  {Object} response  The response sent by the api
 * @return {Stats}            An array where each line contains the stats / priority for a given type
 */
export function formatStats(response: any): Array<Stats> {
  // When showing the stats, "Critical" needs to be first
  const priorities = constants.priorities
    .slice()
    .sort((a, b) => (a.value > b.value ? 1 : -1));

  let stats = [];

  for (const i in constants.types) {
    const type = constants.types[i];

    let in_sla = [];
    let out_sla = [];

    for (const j in priorities) {
      const priority = priorities[j];

      const local_in_sla = response.data.stats.find(
        (item: any) =>
          item.type === type &&
          item.in_sla === 1 &&
          item.priority === priority.value
      ) || { priority: priority.value, total: 0 };
      const local_out_sla = response.data.stats.find(
        (item: any) =>
          item.type === type &&
          item.in_sla === 0 &&
          item.priority === priority.value
      ) || { priority: priority.value, total: 0 };

      in_sla.push({
        priority: priority.label,
        value: local_in_sla.total,
      });

      out_sla.push({
        priority: priority.label,
        value: local_out_sla.total,
      });
    }

    stats.push(
      { type: type, in_sla: 1, data: in_sla },
      { type: type, in_sla: 0, data: out_sla }
    );
  }

  return stats;
}
