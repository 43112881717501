import { call, put, takeLatest } from "redux-saga/effects";

import * as actions from "./actionTypes";
import dashboard from "../../api/dashboard";

import { formatStats } from "./selectors";
import { getErrorMessage } from "@akj-dev/affinity-platform";

function* fetch(action: any): any {
  try {
    const response = yield call(dashboard, action.query);

    // Look at the response and map it to the structure we need
    const stats = formatStats(response);

    yield put({ type: actions.FETCH_OK, payload: stats });
  } catch (error) {
    yield put({
      type: actions.FETCH_FAILED,
      payload: {
        error:
          "There was a problem fetching dashboard stats. Please try again.",
      },
    });

    console.log("fetch dashboard error:", getErrorMessage(error));
  }
}

export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
}
