import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  file: File | null;
  index: number;
  handleRemoveFile: Function;
  uploading?: boolean;
}

function Component({ file, index, handleRemoveFile, uploading }: Props) {
  return (
    <Box key={index} display="flex" alignItems="center" paddingBottom="8px">
      <CheckIcon fontSize="small" />
      <Box paddingLeft="8px" paddingRight="8px" width="200px">
        <Typography noWrap variant="subtitle2">
          {file?.name}
        </Typography>
      </Box>
      <Button
        component="label"
        size="small"
        startIcon={<DeleteIcon />}
        onClick={() => handleRemoveFile(index)}
        disabled={uploading}
      />
    </Box>
  );
}

export default Component;
