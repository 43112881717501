import { useQuery } from "react-query";
import * as api from "../api/tickets";
import { accounts, users } from "../api/autocomplete";
import { ProductType } from "../screens/Tickets/types";
import { LooseObject, QueryResult } from "../types";

export function useAccounts(query?: string, options = {}) {
  return useQuery<{ data: { results: LooseObject[] } }, any>(
    ["accounts", query],
    () => accounts({ query }),
    options
  );
}

export function useCategories(
  type?: string,
  productType?: string,
  options = {}
) {
  return useQuery<QueryResult<string>, any>(
    ["categories", type, productType],
    () => api.categories(type, productType),
    options
  );
}

export function useProducts(type?: string, options = {}) {
  return useQuery<QueryResult<ProductType>, any>(
    ["products", type],
    () => api.products(type || "*"),
    options
  );
}

export function useUsers(query?: string, options = {}) {
  return useQuery<{ data: { results: LooseObject[] } }, any>(
    ["users", query],
    () => users({ query }),
    options
  );
}
