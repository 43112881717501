import React from "react";
import { Provider } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import createSagaMiddleware from "redux-saga";
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {
  ProductContainer,
  ViewContainer,
  ContentContainer,
  SwitchRoutes,
  SubNav,
  StandaloneAppWrapper,
  BrandedDocumentTitle,
} from "@akj-dev/shared-components";
import { useAuth } from "@akj-dev/affinity-platform";

import { getRoutes, ROOT_PATH } from "./routes";
import OneReachChatBot from "./screens/OneReachChatBot";
import Create from "./screens/Tickets/Create";
import Ticket from "./screens/Tickets/Show";
import KnowledgeBase from "./screens/KnowledgeBase";
import reducers from "./store/reducers";
import sagas from "./store/sagas";

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware, thunk];

// Use Redux Dev Tools Chrome Extension if it's installed
// https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middleware))
);

sagaMiddleware.run(sagas);

const queryCache = new QueryCache(); // possible attributes are onError and onSuccess

const queryClient = new QueryClient({
  queryCache, //Set Cache system
  defaultOptions: {
    //Default options for some queries
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30,
    },
  },
});

const AppStandaloneComponent = () => {
  const { accountSettings } = useAuth();
  const routes = getRoutes(accountSettings);
  const { pathname } = useLocation();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ProductContainer>
          <BrandedDocumentTitle title="Support" />
          {pathname.includes(routes.rootPath) && (
            <SubNav routes={routes} rootTitle="Support" />
          )}
          <ViewContainer>
            <ContentContainer>
              <Switch>
                <Route path={routes.rootPath + "/new-ticket"}>
                  <Create />
                </Route>
                <Route path={routes.rootPath + "/tickets/:id"}>
                  <Ticket />
                </Route>
                <Route path={routes.rootPath + "/knowledge-base/*"}>
                  <KnowledgeBase />
                </Route>
                <SwitchRoutes routes={routes} />
              </Switch>
            </ContentContainer>
            {accountSettings.can_use_one_reach_chat_bot_in_affinity_platform ===
              "1" && (
              <OneReachChatBot
                autoOpen={pathname.includes("/support/new-ticket")}
              />
            )}
          </ViewContainer>
        </ProductContainer>
      </Provider>
    </QueryClientProvider>
  );
};

export const AppStandalone = () => (
  <StandaloneAppWrapper root={ROOT_PATH}>
    <AppStandaloneComponent />
  </StandaloneAppWrapper>
);
