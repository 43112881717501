import React from "react";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";

import makeStyles from "@mui/styles/makeStyles";

import FileUpload from "../../../components/FileUpload";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(0.5),
    marginLeft: "auto",
    float: "right",
  },
}));

interface Props {
  fields: any;
  onSubmit: (fields: any) => void;
  update: (fields: any) => void;
  ticketStatus: string;
}

function Component({ fields, onSubmit, update, ticketStatus }: Props) {
  const classes = useStyles();

  const handleChange = (name: string) => (event: any) => {
    update({ [name]: event.target.value });
  };

  const handleCheckboxChange = (event: any) => {
    update({ acknowledge_case: event.target.checked });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    onSubmit(fields);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Comment detail"
            multiline
            name="description"
            onChange={handleChange("description")}
            required
            rows={4}
            size="small"
            value={fields.description}
            variant="outlined"
          />
        </Grid>

        <Grid
          item
          xs={ticketStatus === "S10" || ticketStatus === "Completed" ? 6 : 12}
        >
          <FileUpload
            files={fields.attachments}
            onChange={(files: Array<File | null>) =>
              update({ attachments: files })
            }
            multiple
          />
        </Grid>
        {(ticketStatus === "S10" || ticketStatus === "Completed") && (
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "baseline",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={fields.acknowledge_case}
                  onChange={handleCheckboxChange}
                />
              }
              componentsProps={{ typography: { variant: "h6" } }}
              label="Reopen ticket"
            />
          </Grid>
        )}
      </Grid>

      <Button
        className={classes.button}
        color="primary"
        disabled={!(fields.subject && fields.description)}
        size="medium"
        startIcon={<MessageIcon />}
        type="submit"
        variant="contained"
      >
        Add comment
      </Button>
    </form>
  );
}

export default Component;
