import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { DatePicker as ParentDatePicker } from "@mui/x-date-pickers";
import Wrap from "./Wrap";
import { dateOrNull } from "../../../helpers/functions";
import { TextField } from "@mui/material";

export interface DatePickerProps {
  format?: string;
  label: string;
  onApply: (value: any) => void;
  value?: string | null;
  [key: string]: any;
  minDate?: string | null;
  maxDate?: string | null;
}

export function DatePicker(props: DatePickerProps) {
  const [value, setValue] = useState<any>(dateOrNull(props?.value ?? ""));

  useEffect(() => {
    setValue(dateOrNull(props?.value ?? ""));
  }, [props.format, props.value]);

  return (
    <Wrap
      {...props}
      onApply={() => props.onApply(format(value, props.format ?? "yyy-MM-dd"))}
    >
      <ParentDatePicker
        autoFocus
        disableHighlightToday
        inputFormat={props.format ?? "dd/MM/yyyy"}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, readOnly: true }}
            variant="outlined"
            size="small"
          />
        )}
        label={props.label}
        onChange={(date) => setValue(date)}
        value={value}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </Wrap>
  );
}
