import { getJSON, multiPartPostJSON, postJSON } from "./";

export function create(task_id, params) {
  if (params.attachments) {
    return multiPartPostJSON("Cases/NoteAdd/" + task_id, {
      ...params,
      is_public: 1,
    });
  }

  return postJSON("Cases/NoteAdd/" + task_id, {
    ...params,
    is_public: 1,
  });
}

export function paginate(id, query = { limit: 10, page: 1 }) {
  return getJSON("Cases/Notes/" + id, {
    ...query,
    is_public: 1,
  });
}
