import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StringParam, withDefault, useQueryParams } from "use-query-params";

import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import { Link } from "react-router-dom";

import CardItem from "./components/CardItem";
import CardItemPreview from "./components/CardItemPreview";
import Main from "../../layout/Main";
import RangeSelector from "./components/RangeSelector";

import { fetch } from "../../store/dashboard/actions";
import { RootState } from "../../types";
import { Stats } from "./types";

export default function Dashboard() {
  const { error, loading, stats } = useSelector(
    (state: RootState) => state.dashboard
  );

  const dispatch = useDispatch();

  const [query, setQuery] = useQueryParams({
    open: StringParam,
    range: withDefault(StringParam, "1mo"),
  });

  useEffect(() => {
    dispatch(fetch(query));
  }, [query, dispatch]);

  return (
    <Main
      error={error}
      title="Support"
      loading={loading}
      menu={
        <RangeSelector
          selected={query.range}
          setRange={(range) => setQuery({ range })}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                label={query.open === undefined ? "All Tickets" : null}
                name="open"
                onChange={(event) =>
                  setQuery({ open: event.target.value || undefined })
                }
                select
                size="small"
                value={query.open || ""}
                variant="outlined"
              >
                <MenuItem value="">All Tickets</MenuItem>
                <MenuItem value="1">Open Tickets</MenuItem>
                <MenuItem value="0">Closed Tickets</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} md={9}>
              <Box display="flex" flexDirection="row-reverse">
                <Button
                  color="primary"
                  component={Link}
                  size="medium"
                  to="/support/new-ticket"
                  startIcon={<AddIcon />}
                  variant="contained"
                >
                  New Ticket
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {loading && (
          <Grid item xs={12}>
            <CardItemPreview />
          </Grid>
        )}

        {!loading &&
          stats.map((info: Stats, index: number) => {
            return (
              <Grid item md={6} xs={12} key={`stats-${info.type}`}>
                <CardItem info={info} query={query} />
              </Grid>
            );
          })}
      </Grid>
    </Main>
  );
}
