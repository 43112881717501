import React from "react";
import { useDispatch } from "react-redux";
import { formattedDateTime } from "../../../helpers/functions";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import { fetch } from "../../../store/attachments/actions";

import { Attachment, Comment } from "../types";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.caption.fontSize,
  },
  pagination: {
    marginLeft: "auto",
  },
}));

interface Props {
  count: number;
  data: Array<Comment>;
  limit: number;
  page: number;
  setPagination: (params: any) => void;
}

function Component({ count, data, limit, page, setPagination }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Download attachment function
  const download = (attachment: Attachment) => dispatch(fetch(attachment));

  return (
    <>
      <Table>
        <TableBody>
          {data.map((row) => (
            <TableRow key={`row-${row.name}`}>
              <TableCell>
                <Typography variant="caption">
                  <RadioButtonUncheckedIcon className={classes.icon} />{" "}
                  {formattedDateTime(row.date_entered)}
                </Typography>
                <Typography paragraph={true} variant="h4">
                  {row.created_by_for_display}
                </Typography>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: row.description_as_html || row.name,
                  }}
                />
                {row?.attachments?.map((attachment: Attachment) => (
                  <Button
                    key={attachment.id}
                    onClick={(e) => download(attachment)}
                    size="small"
                    startIcon={<CloudDownloadIcon />}
                    variant="text"
                  >
                    {attachment.filename}
                  </Button>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        className={classes.pagination}
        component="div"
        count={count}
        page={page - 1}
        onPageChange={(event, page) => setPagination({ page: page + 1 })}
        rowsPerPage={limit}
        onRowsPerPageChange={(event) =>
          setPagination({ page: 1, limit: parseInt(event.target.value, 10) })
        }
      />
    </>
  );
}

export default Component;
