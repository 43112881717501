import React, { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface Props {
  label: string;
  file: File;
  onChange: (file: File | null) => void;
  variant?: "text" | "outlined" | "contained";
}

function Component({ file, onChange, label, variant }: Props) {
  useEffect(() => {
    return () => onChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      component="label"
      size="medium"
      startIcon={<AttachFileIcon />}
      variant={variant || "text"}
      fullWidth
      style={{ height: "100%", justifyContent: "left" }}
    >
      <Typography variant="button" noWrap>
        {file ? file.name : label}
      </Typography>
      <input
        hidden
        onChange={(e) => onChange(e.target.files?.[0] ?? null)}
        type="file"
        onClick={(event) => {
          (event.target as HTMLInputElement).value = "";
        }}
      />
    </Button>
  );
}

export default Component;
