import { call, put, takeLatest } from "redux-saga/effects";

import * as actions from "./actionTypes";
import * as autocomplete from "../../api/autocomplete";
import { Account } from "../../types";
import { getErrorMessage } from "@akj-dev/affinity-platform";

function* searchAccounts(action: any): any {
  try {
    const response = yield call(autocomplete.accounts, action.payload);

    // Change the shape of the results to include the id
    const results = response.data?.results?.map((row: Account) => ({
      ...row,
      name: row.name + " - " + row.billing_account_number,
    }));

    yield put({
      type: actions.SEARCH_ACCOUNTS_OK,
      payload: {
        ...response,
        data: {
          results,
        },
      },
    });
  } catch (error) {
    yield put({
      type: actions.SEARCH_ACCOUNTS_FAILED,
      payload: {
        error: "There was a problem when searching accounts. Please try again.",
      },
    });

    console.log("searchAccounts error:", getErrorMessage(error));
  }
}

function* searchCLIs(action: any): any {
  try {
    const response = yield call(autocomplete.clis, action.payload);
    yield put({ type: actions.SEARCH_CLIS_OK, payload: response });
  } catch (error) {
    yield put({
      type: actions.SEARCH_CLIS_FAILED,
      payload: {
        error: "There was a problem when searching clis. Please try again.",
      },
    });

    console.log("searchCLIs error:", getErrorMessage(error));
  }
}

function* searchContacts(action: any): any {
  try {
    const response = yield call(autocomplete.contacts, action.payload);
    yield put({ type: actions.SEARCH_CONTACTS_OK, payload: response });
  } catch (error) {
    yield put({
      type: actions.SEARCH_CONTACTS_FAILED,
      payload: {
        error: "There was a problem when searching contacts. Please try again.",
      },
    });

    console.log("searchContacts error:", getErrorMessage(error));
  }
}

export function* watchSearchAccounts() {
  yield takeLatest(actions.SEARCH_ACCOUNTS, searchAccounts);
}

export function* watchSearchCLIs() {
  yield takeLatest(actions.SEARCH_CLIS, searchCLIs);
}

export function* watchSearchContacts() {
  yield takeLatest(actions.SEARCH_CONTACTS, searchContacts);
}
