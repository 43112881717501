import React from "react";

import Box from "@mui/material/Box";

import Panel from "./Panel";

import { constants } from "../../../helpers";
import { Status, Query } from "../types";

type Props = {
  query: Query;
  resetQuery: () => void;
  setQuery: (query: Partial<Query>) => void;
};

function Component({ query, resetQuery, setQuery }: Props) {
  return (
    <Box display="flex" marginBottom={2}>
      {constants.statuses.map((status: Status) => (
        <Panel
          key={`panel-${status}`}
          resetQuery={resetQuery}
          setQuery={setQuery}
          status={status}
          query={query}
        />
      ))}
    </Box>
  );
}

export default Component;
