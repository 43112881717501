import u from "updeep";

import * as actions from "./actionTypes";
import { constants } from "../../helpers";
import { LooseObject } from "../../types";

const template = {
  count: 0,
  data: [],
  error: "",
  loading: false,
};

let initialState: LooseObject = {
  Table: template,

  error: "",
  loading: false,

  fields: {
    account_id: "",
    contact_id: "",
    description: "",
    email: "",
    "clis[]": [],
    first_name: "",
    last_name: "",
    name: "",
    priority: "",
    product_type: "",
    subtype: "",
    telephone: "",
    type: "Service Request",
    attachments: [],
    transferFiles: { 0: null, 1: null },
    fault_ref: "",
  },

  ticket: {},
};

constants.statuses.forEach((status) => {
  initialState[status] = template;
});

const reducer = (state = initialState, action: any) => {
  const merge = (where: string, what: LooseObject) => {
    return u(
      {
        [where]: what,
      },
      state
    );
  };

  switch (action.type) {
    case actions.CREATE:
      return u(
        {
          error: "",
          loading: true,
        },
        state
      );
    case actions.CREATE_OK:
      return u(
        {
          error: "",
          loading: false,
          fields: initialState.fields,
        },
        state
      );
    case actions.CREATE_FAILED:
      return u(
        {
          ...action.payload,
          loading: false,
        },
        state
      );
    case actions.PAGINATE:
      return merge(action.where, {
        error: "",
        loading: true,
      });
    case actions.PAGINATE_OK:
      let data = action.payload.data.cases || [];

      if (action.append) {
        data = data.concat(state[action.where].data);
      }

      return merge(action.where, {
        count: parseInt(action.payload.data.total) || 0,
        data: data,
        error: "",
        loading: false,
      });
    case actions.PAGINATE_FAILED:
      return merge(action.where, {
        ...action.payload,
        loading: false,
      });
    case actions.SET_FIELDS:
      return u(
        {
          fields: {
            ...action.fields,
          },
        },
        state
      );
    case actions.SHOW:
      return u(
        {
          error: "",
          loading: true,
        },
        state
      );
    case actions.SHOW_OK:
      return u(
        {
          error: "",
          loading: false,
          ticket: action.payload.data,
        },
        state
      );
    case actions.SHOW_FAILED:
      return u(
        {
          ...action.payload,
          loading: false,
        },
        state
      );
    case actions.CLEAR_FIELDS:
      return u(
        {
          fields: { ...initialState.fields, attachments: [] },
        },
        state
      );
    case actions.RESET_ERROR:
      return u(
        {
          error: "",
        },
        state
      );
    default:
      return state;
  }
};

export default reducer;
