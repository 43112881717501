export const SEARCH_ACCOUNTS = "support.autocomplete.SEARCH_ACCOUNTS";
export const SEARCH_ACCOUNTS_FAILED =
  "support.autocomplete.SEARCH_ACCOUNTS_FAILED";
export const SEARCH_ACCOUNTS_OK = "support.autocomplete.SEARCH_ACCOUNTS_OK";

export const SEARCH_CLIS = "support.autocomplete.SEARCH_CLIS";
export const SEARCH_CLIS_FAILED = "support.autocomplete.SEARCH_CLIS_FAILED";
export const SEARCH_CLIS_OK = "support.autocomplete.SEARCH_CLIS_OK";
export const RESET_CLIS = "support.autocomplete.RESET_CLIS";

export const SEARCH_CONTACTS = "support.autocomplete.SEARCH_CONTACTS";
export const SEARCH_CONTACTS_FAILED =
  "support.autocomplete.SEARCH_CONTACTS_FAILED";
export const SEARCH_CONTACTS_OK = "support.autocomplete.SEARCH_CONTACTS_OK";
export const RESET_CONTACTS = "support.autocomplete.RESET_CONTACTS";
