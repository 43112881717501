import { combineReducers } from "redux";

import autocomplete from "./autocomplete/reducer";
import comments from "./comments/reducer";
import dashboard from "./dashboard/reducer";
import tickets from "./tickets/reducer";

const reducer = combineReducers({
  autocomplete,
  comments,
  dashboard,
  tickets,
});

export default reducer;
