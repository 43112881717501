export const limit = {
  comments: 10,
  kanban: 10,
  kanbanMaxItems: 100,
  tickets: 10,
} as const;

export const dashboardDateRanges = [
  {
    id: "24h",
    label: "Last 24 hours",
  },
  {
    id: "1wk",
    label: "This Week",
  },
  {
    id: "1mo",
    label: "This Month",
  },
  {
    id: "1yr",
    label: "This Year",
  },
] as const;

export const priorities = [
  {
    value: "P5",
    label: "Low",
    info: "Any incident not classified as a Medium or above.",
  },
  {
    value: "P4",
    label: "Medium",
    info: `A Service is degraded or impacted affecting a single department or business function of the Customer.
A Service is degraded or a total loss of Service for an individual End User.`,
  },
  {
    value: "P3",
    label: "Serious",
    info: `A total loss of a Service affecting a single department or business function of the Customer.
A Service is degraded or impacted affecting multiple departments or a single Customer Premises.`,
  },
  {
    value: "P2",
    label: "Critical",
    info: `Significant revenue, operational or safety impact on the Customer.
A total loss of Service affecting a single Customer Premises or multiple departments or business functions of the Customer.
A Service is significantly degraded affecting the entire Customer organisation.`,
  },
] as const;

export const productTypes = [
  "Broadband",
  "Calls",
  "Ethernet",
  "Global Voice Services",
  "Mobile",
  "None",
] as const;

export const SLAStatuses = ["In", "Out"] as const;

export const sortDirections = ["asc", "desc", "none"] as const;

export const statuses = [
  "New",
  "In Triage",
  "Assigned",
  "In Progress",
  "Blocked",
  "Resolved",
  "Updated",
  "Passed to Carrier",
  "With Supplier",
  "Awaiting Account Manager",
  "Awaiting Business Partner Feedback",
  "Awaiting Call Back",
  "Awaiting Credit Authorisation",
  "Awaiting Customer",
  "Awaiting Customer Feedback",
] as const;

export const ticketStatusMap = [
  { value: "New", label: "New" },
  { value: "Assigned", label: "Assigned" },
  { value: "Updated", label: "Updated" },
  { value: "Re-Opened", label: "Updated" },
  { value: "Acknowledged", label: "In Triage" },
  { value: "Order Placed", label: "In Progress" },
  { value: "Order Submitted", label: "In Progress" },
  { value: "Engineer Scheduled", label: "In Progress" },
  { value: "Carrier Resolved", label: "In Progress" },
  { value: "S9", label: "In Progress" },
  { value: "S3", label: "Passed to Carrier" },
  { value: "With Supplier", label: "With Supplier" },
  {
    value: "Awaiting Account Manager",
    label: "Awaiting Account Manager",
  },
  {
    value: "Awaiting Business Partner",
    label: "Awaiting Business Partner Feedback",
  },
  { value: "S6", label: "Awaiting Call Back" },
  { value: "S26", label: "Awaiting Credit Authorisation" },
  { value: "Awaiting Customer", label: "Awaiting Customer" },
  {
    value: "Awaiting Customer Feedback",
    label: "Awaiting Customer Feedback",
  },
  { value: "Awaiting Dispute", label: "Blocked" },
  { value: "Awaiting Project", label: "Blocked" },
  { value: "Contact Customer", label: "Blocked" },
  { value: "Pending Input", label: "Blocked" },
  { value: "Retention in Progress", label: "Blocked" },
  { value: "S4", label: "Blocked" },
  { value: "Completed", label: "Resolved" },
  { value: "S10", label: "Resolved" },
] as const;

export const types = ["Service Request", "Incident"] as const;
