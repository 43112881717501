import React from "react";

import { AppBrand, useAuth } from "@akj-dev/affinity-platform";
import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { FiberManualRecord } from "@mui/icons-material";

import { Link } from "react-router-dom";

import { functions } from "../../../../helpers";
import { Ticket } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  card: {
    "&:hover": {
      boxShadow: theme.shadows[12],
    },

    "& .MuiCardContent-root": {
      paddingBottom: 0,
    },

    "& .MuiCardActions-root": {
      "& .sla_status svg": {
        position: "relative",
        top: theme.spacing(0.1),
        width: theme.typography.h5.fontSize,
        height: theme.typography.h5.fontSize,
      },
    },

    "&.in_sla .MuiCardActions-root": {
      borderBottom: `${theme.shape.borderRadius}px solid ${theme.palette.success.main}`,

      "& .sla_status svg": {
        color: theme.palette.success.main,
      },
    },

    "&.out_sla .MuiCardActions-root": {
      borderBottom: `${theme.shape.borderRadius}px solid ${theme.palette.error.main}`,

      "& .sla_status svg": {
        color: theme.palette.error.main,
      },
    },

    "& .MuiGrid-item": {
      padding: theme.spacing(0.25),
    },
  },
  link: {
    textDecoration: "none",
    width: "100%",
  },
}));

interface Props {
  ticket: Ticket;
}

export default function Component({ ticket }: Props) {
  const { accountSettings } = useAuth();
  const classes = useStyles();

  const SLA = () => (
    <Typography className="sla_status" variant="button">
      <FiberManualRecord />{" "}
      {(ticket.sla?.time_remaining || 0) > 0 ? "In SLA" : "Out SLA"}
    </Typography>
  );

  return (
    <Link
      className={classes.link}
      to={"/support/tickets/" + ticket.case_number}
      data-id={ticket.id}
    >
      <Card
        className={
          classes.card +
          " " +
          (accountSettings.affinity_support_permission_view_sla === "1"
            ? (ticket.sla?.time_remaining || 0) > 0
              ? "in_sla"
              : "out_sla"
            : "")
        }
        elevation={3}
      >
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography color="textSecondary" variant="caption">
                {ticket.type}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography align="right" color="textSecondary" variant="caption">
                #{ticket.case_number}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Chip label={ticket.product_type} size="small" />
            </Grid>
            <Grid item xs={12}>
              <Typography noWrap variant="h4">
                {ticket.account_name}
              </Typography>
            </Grid>
            {window.location.hostname.includes(AppBrand.DWS) && (
              <Grid item xs={12}>
                <Typography noWrap variant="subtitle2">
                  {ticket.cust_ref_no}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography align="right" variant="caption">
                {ticket.subtype}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="caption">
                Created by {ticket.created_by_for_display}
              </Typography>
            </Grid>
          </Grid>

          <Divider />
        </CardContent>
        <CardActions>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              {accountSettings.affinity_support_permission_view_sla === "1" && (
                <SLA />
              )}
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography align="right" variant="button">
                {functions.formattedDate(ticket.date_entered, "dd MMM ''yy")}
              </Typography>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Link>
  );
}
