import React from "react";
import { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { FieldProps, getIn } from "formik";
import { CurrencyField } from "./CurrencyField";

export interface FormikCurrencyFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, "name" | "value" | "error"> {}

/**
 * This is a basically a copy of
 * https://github.com/stackworx/formik-material-ui/blob/master/packages/formik-material-ui/src/TextField.tsx
 * ...but I don't want the DS to have a dependency on it just for one function
 */
function fieldToTextField({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}: FormikCurrencyFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

export const FormikCurrencyField = (props: FormikCurrencyFieldProps) => {
  return <CurrencyField {...fieldToTextField(props)} />;
};
