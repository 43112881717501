import React from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Attachment } from "../../../../types";
import { fetch } from "../../../../store/attachments/actions";

interface Props {
  attachments: Array<Attachment>;
  ticketId: string;
}

function Component({ attachments, ticketId }: Props & RouteComponentProps) {
  const dispatch = useDispatch();

  // Download attachment function
  const download = (attachment: Attachment) => dispatch(fetch(attachment));

  return (
    <>
      {attachments?.length > 0 && (
        <Typography paragraph variant="caption">
          Attachments
        </Typography>
      )}

      {attachments?.map((attachment: Attachment) => (
        <Box key={attachment.id} textAlign="left">
          <Button
            onClick={(e) => download(attachment)}
            startIcon={<CloudDownloadIcon />}
            variant="text"
          >
            <Typography
              noWrap
              variant="caption"
              style={{
                paddingLeft: "6px",
              }}
            >
              {attachment.filename}
            </Typography>
          </Button>
        </Box>
      ))}
    </>
  );
}

export default withRouter(Component);
