import React from "react";
import { Helmet } from "react-helmet";
import makeStyles from "@mui/styles/makeStyles";
import { useAuth } from "@akj-dev/affinity-platform";

const useStyles = makeStyles({
  helpjuice: {
    width: "100%",

    "& iframe": {
      width: "100%",
    },
  },
});

function KnowledgeBase() {
  const classes = useStyles();
  const { accountSettings } = useAuth();

  return (
    <>
      <Helmet>
        <script>
          {`var helpjuice_account_url = "https://${
            accountSettings.affinity_support_knowledge_base_domain ?? "dws"
          }.helpjuice.com";`}
        </script>
        <script src="//s3.amazonaws.com/assets.helpjuice.com/helpjuice.min.js" />
      </Helmet>

      <div id="helpjuice_js_widget_content" className={classes.helpjuice}>
        <img
          alt="loading"
          src="//helpjuice.com/images/cube-theme/loading.gif"
          className="helpjuice-loading-spinner"
        />
      </div>
    </>
  );
}

export default KnowledgeBase;
