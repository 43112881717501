import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../../layout/Loading";
import Table from "./components/Table";

import { paginate } from "../../../store/tickets/actions";

import { RootState } from "../../../types";

import { Query } from "../types";

type Props = {
  query: Query;
  setQuery: (query: Partial<Query>) => void;
};

function Component({ query, setQuery }: Props) {
  const { count, data, error, loading } = useSelector(
    (state: RootState) => state.tickets.Table
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paginate("Table", query));
  }, [query, dispatch]);

  return (
    <Loading error={error} loading={false}>
      <Table
        count={count}
        data={data}
        loading={loading}
        query={query}
        setQuery={setQuery}
      />
    </Loading>
  );
}

export default Component;
