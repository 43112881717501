import * as actions from "./actionTypes";

export const create = (ticket_id: string, payload: any) => {
  return { type: actions.CREATE, ticket_id, payload };
};

export const fetch = (ticket_id: string, query: any) => {
  return { type: actions.FETCH, ticket_id, query };
};

export const setFields = (fields: any) => {
  return { type: actions.SET_FIELDS, fields };
};
