import * as actions from "./actionTypes";

import { Query } from "../../screens/Tickets/types";

export const create = (payload: any) => {
  return { type: actions.CREATE, payload };
};

export const paginate = (
  where: string,
  query: Query,
  append: boolean = false
) => {
  return { type: actions.PAGINATE, append, query, where };
};

export const setFields = (fields: any) => {
  return { type: actions.SET_FIELDS, fields };
};

export const show = (id: string) => {
  return { type: actions.SHOW, id };
};

export const clearFields = () => {
  return { type: actions.CLEAR_FIELDS };
};

export const resetError = () => {
  return { type: actions.RESET_ERROR };
};
