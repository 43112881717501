import React, { useState } from "react";
import { CircularProgress, TextField } from "@mui/material";
import { Autocomplete as ParentAutocomplete } from "@mui/material";
import Wrap from "./Wrap";

export interface AutocompleteProps {
  loading?: boolean;
  label: string;
  multiple?: boolean;
  onSearch?: (query: string) => void;
  onSelect: (selected: any) => void;
  options: any[];
  selected?: any;

  [key: string]: any;
}

export function Autocomplete({ multiple = true, ...props }: AutocompleteProps) {
  const [selected, setSelected] = useState<any>(props.selected);

  return (
    <Wrap {...props} onApply={() => props.onSelect(selected)}>
      <ParentAutocomplete
        autoComplete
        defaultValue={props.defaultValue}
        filterSelectedOptions
        fullWidth
        getOptionLabel={(option) => {
          if (typeof option === "object") {
            if (typeof props.labelFrom === "function") {
              return props.labelFrom(option);
            }

            return option[props.labelFrom || "text"];
          }

          return option;
        }}
        isOptionEqualToValue={(selected, item) => {
          if (typeof selected === "object") {
            if (typeof props.valueFrom === "function") {
              return props.valueFrom(selected) === props.valueFrom(item);
            }

            return (
              selected[props.valueFrom || "value"] ===
              item[props.valueFrom || "value"]
            );
          }

          return selected === item;
        }}
        onInputChange={(event, value, reason) => {
          if (
            typeof props.onSearch === "function" &&
            (reason === "input" || reason === "clear")
          ) {
            props.onSearch(value);
          }
        }}
        multiple={props.multiple}
        onChange={(event, selected) => setSelected(selected)}
        onBlur={() => (props.onSearch ? props.onSearch("") : null)}
        options={props.options}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            fullWidth
            placeholder="Search"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {props.loading ?? false ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginRight: "-28px" }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        size="small"
      />
    </Wrap>
  );
}
