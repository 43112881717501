import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Alert } from "@akj-dev/shared-components";
import { useAuth } from "@akj-dev/affinity-platform";

import Autocomplete from "../../../../components/Autocomplete";
import FileUpload from "../../../../components/FileUpload";
import SingleFileUpload from "../../../../components/SingleFileUpload";

import { constants } from "../../../../helpers";

import {
  Account,
  Autocomplete as AutocompleteInterface,
  CLI,
  Contact,
  Priority,
  TicketType,
} from "../../../../types";
import { searchCLIs } from "../../../../store/autocomplete/actions";
import {
  categories,
  products,
  subCategories,
  types,
} from "../../../../api/tickets";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0.5),
    marginLeft: "auto",
  },
  group: {
    marginBottom: theme.spacing(1.5),
  },
  selectedType: {
    width: "100%",
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
  },
  fullWidth: {
    width: "100%",
  },
}));

type GridGroupProps = {
  children: React.ReactNode;
};

function GridGroup({ children }: GridGroupProps) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.group}>
      <Grid container spacing={1}>
        {children}
      </Grid>
    </Grid>
  );
}

interface MultilineAlertProps {
  message: string;
}

function MultilineAlert({ message }: MultilineAlertProps) {
  const newText = message
    .split("\n")
    .map((line) => <div key={line}>{line}</div>);

  return <Alert message={newText} type="info" />;
}

interface Props {
  accounts: AutocompleteInterface<Account>;
  clis: AutocompleteInterface<CLI>;
  contacts: AutocompleteInterface<Contact>;
  fields: any;
  searchAccounts: (query: string) => void;
  searchContacts: (query: string) => void;
  setFields: (fields: any) => void;
  onSubmit: (fields: any) => void;
}

function Component({
  accounts,
  clis,
  contacts,
  fields,
  searchAccounts,
  searchContacts,
  setFields,
  onSubmit,
}: Props) {
  const { accountSettings, externalUserName } = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [chargeAccepted, setChargeAccepted] = useState(false);
  const [acceptDialogType, setAcceptDialogType] = useState("");
  const [cliInput, setCliInput] = useState("");
  const [cliValues, setCliValues] = useState<string[] | undefined>();
  const [filteredClis, setFilteredClis] = useState<CLI[]>([]);
  let handleSelectContact: Function = () => {
    return;
  };

  useEffect(() => {
    if (!cliValues || (cliValues && cliValues?.length === 0)) {
      setCliValues(fields["clis[]"]);
    }
  }, [clis.options, fields, setCliValues, cliValues]);

  useEffect(() => {
    if (fields.account_id && fields.product_type) {
      setCliValues([]);
      dispatch(searchCLIs(fields.account_id, fields.product_type, "", 1));
    }
  }, [fields.account_id, fields.product_type, dispatch]);

  useEffect(() => {
    if (fields.account_id) {
      dispatch(searchContacts(externalUserName ?? ""));
    }
  }, [fields.account_id, externalUserName, dispatch, searchContacts]);

  useEffect(() => {
    if (
      contacts.options.length === 1 &&
      (contacts.options[0].nice_name === `Mr ${externalUserName}` || // leave in just for testing, not needed for live
        contacts.options[0].nice_name === externalUserName)
    ) {
      handleSelectContact(contacts.options[0]);
    }
  }, [fields.account_id, contacts.options, externalUserName, dispatch]);

  const cliOptions = (filteredClis.length ? filteredClis : clis.options).filter(
    (cli) => !fields["clis[]"]?.includes(cli.pin)
  );

  const onCliSearch = (query: string) => {
    setCliInput(query);
    const filtered = clis.options.filter((cli: CLI) =>
      cli.pin.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredClis(filtered);
  };

  const handleChange = (field: string, extra?: any) => (event: any) => {
    if (event) {
      let value = "";
      if (event.target) {
        value = event.target.value;
      } else if (event.value) {
        value = event.value;
      } else {
        value = event;
      }

      setFields({
        [field]: value,
        ...extra,
      });
    }
  };

  const handleSelectAccount = (account: Account) => {
    setFields({ account_id: account ? account.id : "" });
  };

  const handleSelectProductType = (event: any) => {
    setFields({ product_type: event.target.value, "clis[]": [], subtype: "" });
  };

  const handleSelectCategory = (event: any) => {
    setFields({
      subtype: event.target.value,
      name: event.target.value,
      _name: event.target.value,
    });
    if (
      event.target.value === "Transfer of Ownership" ||
      event.target.value === "Manual Port"
    )
      setAcceptDialogType(event.target.value);
  };

  const handleSelectSubCategory = (event: any) => {
    setFields({
      fault_ref: event.target.value,
      name: fields._name + " - " + event.target.value,
    });
  };

  handleSelectContact = (contact: Contact) => {
    if (contact) {
      setFields({
        contact_id: contact.id,
        first_name: contact.first_name || "",
        last_name: contact.last_name || "",
        email: contact.email || "",
        telephone: contact.telephone || "",
      });
    } else {
      setFields({
        contact_id: "",
        first_name: "",
        last_name: "",
        email: "",
        telephone: "",
      });
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (
      fields.product_type === "Mobile" &&
      fields.subtype === "MPN Change" &&
      chargeAccepted
    ) {
      onSubmit({
        ...fields,
        description: `${fields.description} (Customer accepts the charge to change the MPN.)`,
      });
    } else {
      onSubmit(fields);
    }
  };

  const typesQuery = useQuery("types", types);
  const productsQuery = useQuery(
    ["products", fields.type],
    () => products(fields.type),
    { enabled: !!fields.type }
  );
  const categoriesQuery = useQuery(
    ["categories", fields.type, fields.product_type],
    () => categories(fields.type, fields.product_type),
    { enabled: !!fields.product_type }
  );
  const subCategoriesQuery = useQuery(
    ["subcategories", fields.type, fields.product_type, fields.subtype],
    () => subCategories(fields.type, fields.product_type, fields.subtype),
    { enabled: !!fields.subtype }
  );
  const cliRequiredCategories = [
    "Billing Query",
    "Call Divert",
    "Change Lead / Sharer",
    "Cost Centre/BEN Changes",
    "Disconnection Request",
    "IMEI Unlock",
    "MPN Change",
    "Number Change",
    "PUK / PUK2",
    "E-SIM Swap",
  ];

  const ToggleButtonWithTooltip = ({
    tooltipText,
    value,
    ...rest
  }: {
    tooltipText: string;
    [x: string]: any;
  }) => {
    return (
      <Tooltip title={tooltipText}>
        <ToggleButton value={value} {...rest} />
      </Tooltip>
    );
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Card>
        <CardContent>
          <GridGroup>
            <Grid item xs={12} md={6}>
              {typesQuery.isLoading && (
                <Typography variant="caption">
                  <CircularProgress size={12} /> Loading ticket types
                </Typography>
              )}

              <ToggleButtonGroup
                exclusive
                onChange={(event: any, value: string) =>
                  handleChange("type")(value)
                }
                size="small"
                value={fields.type}
                className={classes.fullWidth}
              >
                {typesQuery.data?.results?.map((option: TicketType) => (
                  <ToggleButtonWithTooltip
                    key={option.value}
                    tooltipText={option.info}
                    value={option.value}
                    className={
                      option.value === fields.type
                        ? classes.selectedType
                        : classes.fullWidth
                    }
                  >
                    {option.label}
                  </ToggleButtonWithTooltip>
                ))}
              </ToggleButtonGroup>
            </Grid>
          </GridGroup>

          <GridGroup>
            <Grid item xs={12} md={6}>
              <Autocomplete
                label="Company Name"
                placeholder="Start typing to search for a company"
                loading={accounts.loading}
                name="account_id"
                onSearch={(query: string) => searchAccounts(query)}
                onSelect={(selected: Account) => handleSelectAccount(selected)}
                options={accounts.options}
                value={
                  accounts.options.find(
                    (account: Account) => account.id === fields.account_id
                  ) || null
                }
                valueFrom="id"
                textFrom="name"
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Customer Reference"
                name="cust_ref_no"
                onChange={handleChange("cust_ref_no")}
                value={fields.cust_ref_no}
                variant="outlined"
                size="small"
              />
            </Grid>
          </GridGroup>

          {fields.account_id && fields.type && (
            <>
              <GridGroup>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    label="Select Contact"
                    loading={contacts.loading}
                    name="contact_id"
                    onSearch={(query) => searchContacts(query)}
                    onSelect={(selected) => handleSelectContact(selected)}
                    options={contacts.options}
                    placeholder="Start typing to search for a contact"
                    value={
                      contacts.options.find(
                        (contact: Contact) => contact.id === fields.contact_id
                      ) || null
                    }
                    valueFrom="id"
                    textFrom="nice_name"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} />

                {fields.contact_id && (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="First Name"
                        name="first_name"
                        onChange={handleChange("first_name")}
                        required
                        value={fields.first_name}
                        variant="outlined"
                        size="small"
                        disabled={!!fields.contact_id}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        name="last_name"
                        onChange={handleChange("last_name")}
                        required
                        value={fields.last_name}
                        variant="outlined"
                        size="small"
                        disabled={!!fields.contact_id}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Email Address"
                        name="email"
                        onChange={handleChange("email")}
                        required
                        value={fields.email}
                        variant="outlined"
                        size="small"
                        disabled={!!fields.contact_id}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Phone"
                        name="telephone"
                        onChange={handleChange("telephone")}
                        required
                        value={fields.telephone}
                        variant="outlined"
                        size="small"
                        disabled={!!fields.contact_id}
                      />
                    </Grid>
                  </>
                )}
              </GridGroup>
              <GridGroup>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={productsQuery.isLoading}
                    fullWidth
                    label="Select Product"
                    name="product_type"
                    onChange={handleSelectProductType}
                    required
                    size="small"
                    select
                    value={fields.product_type}
                    variant="outlined"
                    data-cy="productTypeSelect"
                  >
                    {productsQuery.isLoading && <MenuItem>Loading</MenuItem>}
                    {productsQuery.data?.results?.map((option: string) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  {productsQuery.isLoading && (
                    <Typography variant="caption">
                      <CircularProgress size={12} /> Loading products types
                    </Typography>
                  )}
                </Grid>
                {fields.product_type && fields.product_type !== "None" && (
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      label="Identifier"
                      loading={clis.loading}
                      name="clis[]"
                      onSearch={(query) => onCliSearch(query)}
                      onSelect={(selected: CLI[]) => {
                        setCliValues(selected.map((cli: CLI) => cli.pin));
                        setCliInput("");
                        setFilteredClis([]);
                        return setFields({
                          "clis[]": selected?.length
                            ? selected.map((cli: CLI) => cli.pin)
                            : null,
                        });
                      }}
                      onBlur={() => setCliInput("")}
                      options={cliOptions}
                      placeholder="Type at least 3 characters to search a CLI, MPN, BBEU"
                      required={
                        cliRequiredCategories.includes(fields._name) &&
                        !cliValues?.length
                      }
                      inputValue={cliInput}
                      value={
                        (cliValues &&
                          cliValues.length &&
                          clis.options?.filter((option: CLI) =>
                            cliValues.some((cli: string) => cli === option?.pin)
                          )) ||
                        []
                      }
                      valueFrom="id"
                      textFrom="pin"
                      multiple
                      renderTags={(values: CLI[]) =>
                        values.map((value) =>
                          value.info ? (
                            <Tooltip
                              key={value.pin}
                              title={value.info}
                              placement="top"
                              arrow
                            >
                              <Box mr={0.25}>
                                <Chip label={value.pin} size="small" />
                              </Box>
                            </Tooltip>
                          ) : (
                            <Chip label={value.pin} size="small" />
                          )
                        )
                      }
                    />
                  </Grid>
                )}
                {categoriesQuery.data?.results?.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Category"
                      name="subtype"
                      onChange={handleSelectCategory}
                      required
                      size="small"
                      select
                      value={fields.subtype}
                      variant="outlined"
                    >
                      {categoriesQuery.data?.results?.map((option: string) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {fields.product_type === "Mobile" &&
                  fields.subtype === "MPN Change" && (
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={chargeAccepted}
                            onChange={() => setChargeAccepted(!chargeAccepted)}
                          />
                        }
                        label="Check to confirm that the customer accepts the £25 + VAT charge to change the MPN."
                      />
                    </Grid>
                  )}
                {fields.subtype === "Transfer of Ownership" && (
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Box height="40px">
                          <SingleFileUpload
                            label="Attach document 1"
                            file={fields.transferFiles[0]}
                            onChange={(file: File | null) =>
                              setFields({ transferFiles: { 0: file } })
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box height="40px">
                          <SingleFileUpload
                            label="Attach document 2"
                            file={fields.transferFiles[1]}
                            onChange={(file: File | null) =>
                              setFields({ transferFiles: { 1: file } })
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {fields.subtype === "Manual Port" && (
                  <Grid item xs={6}>
                    <Box height="40px">
                      <SingleFileUpload
                        label="Attach document"
                        file={fields.transferFiles[0]}
                        onChange={(file: File | null) =>
                          setFields({ transferFiles: { 0: file } })
                        }
                      />
                    </Box>
                  </Grid>
                )}
                {fields.product_type === "Mobile" &&
                  fields.subtype === "Disconnection Request" && (
                    <Grid item xs={12} md={6}>
                      <Box mt={0.5}>
                        <Typography>
                          Disconnection requests will be set to complete in 30
                          days time
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                {subCategoriesQuery.data?.results?.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Sub Category"
                      name="fault_ref"
                      onChange={handleSelectSubCategory}
                      required
                      size="small"
                      select
                      value={fields.fault_ref}
                      variant="outlined"
                    >
                      {subCategoriesQuery.data?.results?.map(
                        (option: string) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Grid>
                )}
              </GridGroup>

              <GridGroup>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    onChange={handleChange("description")}
                    required
                    multiline
                    rows={5}
                    value={fields.description}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 64000 }}
                    helperText={
                      fields.description.length === 64000 &&
                      "Maximum description length reached"
                    }
                  />

                  {fields.type === "Incident" &&
                    (fields.product_type === "IP Voice" ||
                      fields.product_type === "Calls") && (
                      <Alert
                        type="info"
                        message="Please provide call examples from the past 48 hours and attach any call traces, screen shots etc."
                      />
                    )}
                </Grid>
              </GridGroup>

              {accountSettings.affinity_support_permission_set_severity ===
                "1" && (
                <GridGroup>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Severity"
                      name="priority"
                      onChange={handleChange("priority")}
                      required
                      size="small"
                      select
                      value={fields.priority}
                      variant="outlined"
                    >
                      {constants.priorities.map((option: Priority) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    {fields.priority && (
                      <Box mt={1}>
                        <MultilineAlert
                          message={
                            (
                              constants.priorities.find(
                                (option: Priority) =>
                                  option.value === fields.priority
                              ) || ({} as Priority)
                            ).info || ""
                          }
                        />
                      </Box>
                    )}
                  </Grid>
                </GridGroup>
              )}
            </>
          )}

          <Box paddingTop="16px">
            <FileUpload
              files={fields.attachments}
              onChange={(files: Array<File | null>) =>
                setFields({ attachments: files })
              }
              multiple
              variant="outlined"
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            className={classes.button}
            color="primary"
            disabled={
              !(fields.account_id && fields.type) ||
              (fields.product_type === "Mobile" &&
                fields.subtype === "MPN Change" &&
                !chargeAccepted) ||
              (fields.subtype === "Transfer of Ownership" &&
                (!fields.transferFiles[0] || !fields.transferFiles[1]))
            }
            size="medium"
            type="submit"
            variant="contained"
          >
            Open New Ticket
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={!!acceptDialogType}
        onClose={() => setAcceptDialogType("")}
        TransitionProps={{ unmountOnExit: true }}
      >
        <DialogTitle>
          {acceptDialogType === "Transfer of Ownership"
            ? "Please attach a completed Transfer of Ownership form and PO's from the losing and gaining customer before submitting your ticket."
            : acceptDialogType === "Manual Port"
            ? "Please attach a completed Connection Request document and Vodafone D-Contract. The templates are available in the Knowledge bank. If Additional Funding or Discounts are required, please attach authorisation to avoid delays in provisioning."
            : ""}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setAcceptDialogType("")}
            variant="contained"
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default Component;
