import React from "react";
import { Link } from "react-router-dom";
import {
  NumberParam,
  StringParam,
  withDefault,
  useQueryParam,
  useQueryParams,
} from "use-query-params";
import { Box, ButtonGroup, Button, Grid } from "@mui/material";
import {
  Add as AddIcon,
  Apps as AppsIcon,
  List as ListIcon,
} from "@mui/icons-material";
import Main from "../../layout/Main";
import Kanban from "./Kanban";
import Table from "./Table";
import { constants } from "../../helpers";
import {
  ProductTypeQueryParam,
  SLAStatusQueryParam,
  SortDirectionQueryParam,
  StatusQueryParam,
  TypeQueryParam,
} from "./types";
import Filters from "./Table/components/Filters";

function Tickets() {
  const [view, setView] = useQueryParam(
    "view",
    withDefault(StringParam, "table")
  );

  const [query, setQuery] = useQueryParams({
    case_number: StringParam,
    account_id: StringParam,
    account_name: StringParam,
    status: StatusQueryParam,
    type: TypeQueryParam,
    product_type: ProductTypeQueryParam,
    subtype: StringParam,
    cli: StringParam,
    range: StringParam,
    created_before: StringParam,
    created_after: StringParam,
    resolved_after: StringParam,
    resolved_before: StringParam,
    sla: SLAStatusQueryParam,
    open: StringParam,
    priority: StringParam,
    created_by: StringParam,
    limit: withDefault(NumberParam, constants.limit.tickets),
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, "case_number"),
    order: withDefault(SortDirectionQueryParam, "desc"),
  });

  const resetQuery = () => {
    let newFilters: { [key: string]: any } = {};

    for (let filter in query) {
      newFilters[filter] = undefined;
    }

    setQuery(newFilters);
  };

  const Menu = (
    <ButtonGroup size="small" variant="outlined">
      <Button
        disabled={view === "table"}
        name="view-table"
        onClick={() => setView("table")}
      >
        <ListIcon />
      </Button>
      <Button
        disabled={view === "kanban"}
        name="view-kanban"
        onClick={() => setView("kanban")}
      >
        <AppsIcon />
      </Button>
    </ButtonGroup>
  );

  return (
    <Main error="" title="Tickets" loading={false} menu={Menu}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row-reverse">
            <Button
              color="primary"
              component={Link}
              size="medium"
              to="/support/new-ticket"
              startIcon={<AddIcon />}
              variant="contained"
            >
              New Ticket
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pt={1} data-cy="tickets-filters">
            <Filters
              query={query}
              setQuery={(query) => setQuery({ ...query })}
              resetQuery={resetQuery}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {view === "table" && <Table setQuery={setQuery} query={query} />}
          {view !== "table" && (
            <Kanban resetQuery={resetQuery} setQuery={setQuery} query={query} />
          )}
        </Grid>
      </Grid>
    </Main>
  );
}

export default Tickets;
