import React from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import { dashboardDateRanges } from "../../../helpers/constants";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  group: {
    backgroundColor: theme.palette.common.white,
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}));

interface Props {
  selected: string;
  setRange: (range: string) => void;
}

function Component({ selected, setRange }: Props) {
  const classes = useStyles();
  return (
    <ButtonGroup className={classes.group}>
      {dashboardDateRanges.map((range) => {
        return (
          <Button
            className={selected === range.id ? classes.button : ""}
            key={range.id}
            onClick={() => setRange(range.id)}
            variant={selected === range.id ? "contained" : "outlined"}
          >
            {range.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}

export default Component;
